import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";

function CustomerMenu({ show, handleClose, user }) {
  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="mx-auto">MENÚ</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="offCanvasMenuList">
            <Link to="/avisar">
              <li onClick={handleClose}>
                <i className="bi bi-bell fs-5 me-2"></i>AVISAR
              </li>
            </Link>
            <hr />
            {!user && (
              <>
                <Link to="/clientes/login">
                  <li onClick={handleClose}>
                    <i className="bi bi-person-check fs-4 me-2"></i>INICIAR
                    SESIÓN
                  </li>
                </Link>
                <hr />
                <Link to="/clientes/registro">
                  <li onClick={handleClose}>
                    <i className="bi bi-person-plus fs-4 me-2"></i>NUEVO USUARIO
                  </li>
                </Link>
                <hr />
              </>
            )}
            {user && user.role === "customer" && (
              <>
                <Link to="/clientes/dashboard">
                  <li onClick={handleClose}>
                    <i className="bi bi-graph-up-arrow fs-5 me-2"></i>DASHBOARD
                  </li>
                </Link>
                <hr />
                <Link to="/clientes/vehiculos">
                  <li onClick={handleClose}>
                    <i className="bi bi-car-front fs-5 me-2"></i>MIS VEHÍCULOS
                  </li>
                </Link>
                <hr />
                <Link to="/clientes/propiedades">
                  <li onClick={handleClose}>
                    <i className="bi bi-buildings fs-5 me-2"></i>MIS PROPIEDADES
                  </li>
                </Link>
                <hr />
                <Link to="/clientes/historial">
                  <li onClick={handleClose}>
                    <i className="bi bi-clock-history fs-5 me-2"></i>HISTORIAL
                  </li>
                </Link>
                <hr />
                <Link to="/clientes/perfil">
                  <li onClick={handleClose}>
                    <i className="bi bi-person-lines-fill fs-5 me-2"></i>MIS
                    DATOS
                  </li>
                </Link>
                <hr />
              </>
            )}
            <Link to="/productos">
              <li onClick={handleClose}>
                <i className="bi bi-bag-fill fs-5 me-2"></i>PRODUCTOS
              </li>
            </Link>
            <hr />
            <Link to="/contacto">
              <li onClick={handleClose}>
                <i className="bi bi-envelope-at fs-5 me-2"></i>CONTACTO
              </li>
            </Link>
            <hr />
            <Link to="/legal">
              <li onClick={handleClose}>
                <i className="bi bi-briefcase fs-5 me-2"></i>TÉRMINOS Y
                CONDICIONES
              </li>
            </Link>
            <hr />
            <Link to="/sobre_nosotros">
              <li onClick={handleClose}>
                <i className="bi bi-info-circle fs-5 me-2"></i>SOBRE NOSOTROS
              </li>
            </Link>
            <hr />
            <Link to="/preguntas_frecuentes">
              <li onClick={handleClose}>
                <i className="bi bi-question-circle fs-5 me-2"></i>PREGUNTAS
                FRECUENTES
              </li>
            </Link>
            <hr />
            {user && (
              <>
                <Link to="/clientes/logout">
                  <li onClick={handleClose}>
                    <i className="bi bi-box-arrow-left fs-5 me-2"></i>SALIR
                  </li>
                </Link>
                <hr />
              </>
            )}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default CustomerMenu;

import "./SellerPanel.css";
import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import SellerMenu from "../seller/SellerMenu";
import SellerNavbar from "../seller/SellerNavbar";

function SellerPanel() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const toggleShowOffcanvas = () => setShow((prev) => !prev);

  useEffect(() => {
    navigate("/ventas/dashboard");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <SellerNavbar toggleShowOffcanvas={toggleShowOffcanvas} />
      <SellerMenu show={show} handleClose={handleClose} />
      <Container>
        <Outlet />
      </Container>
    </>
  );
}

export default SellerPanel;

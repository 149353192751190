import axios from "axios";
import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";

function EstatePresets() {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const getMessages = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/predefinidos_propiedades`
      );
      setMessages(response.data);
    };
    getMessages();
  }, []);

  return messages.length ? (
    <div>
      <h1 className="text-center my-3">
        Mensajes predefinidos para propiedades
      </h1>
      <Table responsive striped bordered hover className="p-0 m-0">
        <thead className="bg-dark text-light">
          <tr className="text-center">
            <th className="idTableField">Id</th>
            <th>Texto</th>
            <th>Creado</th>
            <th>Modificado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {messages.map((message) => (
            <tr key={message.id}>
              <td className="idTableField">{message.id}</td>
              <td>{message.text.toUpperCase()}</td>
              <td>{new Date(message.createdAt).toLocaleDateString("es-ES")}</td>
              <td>{new Date(message.updatedAt).toLocaleDateString("es-ES")}</td>
              <td>
                <div className="d-flex justify-content-evenly">
                  <i className="bi bi-trash3 text-secondary fs-5"></i>
                  <i className="bi bi-pencil-square text-danger fs-5"></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  ) : (
    <p className="text-danger">
      No hay mensajes predefinidos de propiedades para mostrar.
    </p>
  );
}

export default EstatePresets;

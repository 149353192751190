import axios from "axios";
import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";

function Logs() {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const getLogs = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/registros`
      );
      setLogs(response.data);
    };
    getLogs();
  }, []);

  return logs.length ? (
    <div>
      <h1 className="text-center my-3">Registro de mensajes enviados</h1>
      <Table responsive striped bordered hover className="p-0 m-0">
        <thead className="bg-dark text-light">
          <tr className="text-center">
            <th className="idTableField">Id</th>
            <th>Enviado a</th>
            <th>Tipo</th>
            <th>Vehícuo/Propiedad</th>
            <th>Mensaje</th>
            <th>Fecha</th>
            <th>Cliente</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log) => (
            <tr key={log.id}>
              <td className="idTableField">{log.id}</td>
              <td>
                {log.receiver[0]} {log.receiver[1] && log.receiver[1]}
              </td>
              <td>{log.type}</td>
              <td>{log.details.sentTo}</td>
              <td>{log.details.text.toUpperCase()}</td>
              <td>{new Date(log.createdAt).toLocaleDateString("es-ES")}</td>
              <td>{log.customerId}</td>
              <td>
                <div className="d-flex justify-content-evenly">
                  <i className="bi bi-trash3 text-secondary fs-5"></i>
                  <i className="bi bi-pencil-square text-danger fs-5"></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  ) : (
    <p className="text-danger">No hay historial para mostrar.</p>
  );
}

export default Logs;

import axios from "axios";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function DashboardModal({ modalShow, onHide, type }) {
  const user = useSelector((state) => state.user);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const navigate = useNavigate();

  /*
    onHide();
    const entity = itemToDelete.plate ? "vehiculos" : "propiedades";
    const response = await axios({
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      url: `${process.env.REACT_APP_API_URL}/api/${entity}/${itemToDelete.id}`,
    });
    if (response.data.msg) return toast.error(response.data.msg);
    setCustomer(
      itemToDelete.plate
        ? {
            ...customer,
            vehicles: customer.vehicles.filter(
              (vehicle) => vehicle.id !== itemToDelete.id
            ),
          }
        : {
            ...customer,
            estates: customer.estates.filter(
              (estate) => estate.id !== itemToDelete.id
            ),
          }
    ); */
  /*     return toast.success(
      itemToDelete.plate
        ? "SE HA ELIMINADO VEHÍCULO PERMANENTEMENTE."
        : "SE HA ELIMINADO PROPIEDAD PERMANENTEMENTE."
    );
  }; */

  useEffect(() => {
    if (type === "globalLogout") {
      setTitle("DESCONECTAR DISPOSITIVOS");
      setContent(
        <>
          <p>
            SE PROCEDERÁ A CERRAR SESIÓN EN TODOS LOS DISPOSITIVOS, INCLUYENDO
            ESTE (ESTO ES ÚTIL CUANDO SE PIERDE O ES ROBADO UN DISPOSITIVO).
            PARA INICIAR NUEVAMENTE DEBERÁ INGRESAR TELÉFONO Y CONTRASEÑA.
          </p>
          <p>¿DESEA CONTINUAR?</p>
        </>
      );
    } else if (type === "unsubscribe") {
      setTitle("BAJA DEL SERVICIO");
      setContent(
        <>
          <p>
            SE PROCEDERÁ A BORRAR SU USUARIO COMPLETO, INFORMACIÓN PERSONAL,
            VEHÍCULOS, PROPIEDADES E HISTORIAL. SI CONTINÚA NO PODRÁ REVERTIR
            LOS CAMBIOS. ESTE USUARIO SERÁ TOTALMENTE DESVINCULADO DE NUESTRA
            APLICACIÓN.
          </p>
          <p>¿DESEA CONTINUAR?</p>
        </>
      );
    }
  }, [type]);

  const handleGlobalDisconnect = async () => {
    const response = await axios({
      url: process.env.REACT_APP_API_URL + "/api/clientes/clear_tokens",
      method: "delete",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    if (response.data.msg !== "cleared")
      return toast.error(
        "ALGO SALIÓ MAL, VERIFIQUE O VUELVA A INTENTARLO EN UNOS MINUTOS."
      );
    toast.info("SE HA DESCONECTADO SU USUARIO DE TODOS LOS DISPOSITIVOS.");
    navigate("/clientes/logout");
  };

  const handleUnsubscribe = async () => {
    const response = await axios({
      url: process.env.REACT_APP_API_URL + "/api/clientes",
      method: "delete",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    response && toast.warn("SE HA DADO DE BAJA EL USUARIO.");
    navigate("/clientes/logout");
  };

  return (
    <Modal
      show={modalShow}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h1>{title}</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={onHide} className="btn btn-dark">
          CANCELAR
        </button>
        <button
          type="button"
          onClick={() =>
            type === "unsubscribe"
              ? handleUnsubscribe()
              : handleGlobalDisconnect()
          }
          className="btn btn-success"
        >
          CONTINUAR
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default DashboardModal;

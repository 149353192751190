import { useState, useEffect } from "react";

//import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import avisaleLogo from "../../img/avisaleLogo.png";

import CustomerNavbar from "../public/PublicNavbar";
import CustomerMenu from "../public/PublicMenu";
import styles from "../customer/Customer.module.css";
import { Link, Outlet } from "react-router-dom";
import PublicLargeMenu from "../public/PublicLargeMenu";

function CustomerPanel() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const toggleShowOffcanvas = () => setShow((prev) => !prev);

  const user = useSelector((state) => state.user);
  //const navigate = useNavigate();

  return (
    <div className={`row g-0 ${styles.homeRow} ${styles.customerPanel}`}>
      <div
        className={`col-md-4 col-lg-7 d-none d-md-flex justify-content-center align-items-center ${styles.homeLeft}`}
      >
        <Link to="/avisar">
          <img src={avisaleLogo} id="brandLogo" className="w-100 px-5" alt="" />
        </Link>
      </div>
      <div id="homeRight" className={`col-md-8 col-lg-5 ${styles.homeRigth}`}>
        <PublicLargeMenu
          toggleShowOffcanvas={toggleShowOffcanvas}
          show={show}
        />
        <CustomerNavbar toggleShowOffcanvas={toggleShowOffcanvas} />
        <CustomerMenu handleClose={handleClose} show={show} user={user} />
        <div className="d-flex px-4 pb-4 justify-content-center w-100">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default CustomerPanel;

import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AdminPrivateRoutes = ({ children }) => {
  const user = useSelector((state) => state.user);
  if (user && user.token && user.role === "customer") {
    return children ? children : <Outlet />;
  }
  return <Navigate to="/clientes/login" replace />;
};

export default AdminPrivateRoutes;

import { useState, useRef, useEffect } from "react";
import CustomTooltip from "../CustomTooltip";
//import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function Products() {
  const [showTip, setShowTip] = useState(false);
  const [tollTipText, setTollTipText] = useState("");
  const target = useRef(null);

  const handleTip = (text, selected) => {
    target.current = selected.target;
    setTollTipText(text);
    setShowTip(!showTip);
  };

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (showTip && target.current !== e.target) setShowTip(false);
    });
    return () => {
      document.removeEventListener("click", (e) => {
        if (showTip && target.current !== e.target) setShowTip(false);
      });
    };
  });

  return (
    <div
      className="productContainer w-100"
      onClick={() => showTip && setShowTip(false)}
    >
      <h1 className="text-center m-0">PRODUCTOS</h1>
      <hr />

      <div className="shadow rounded-2 mb-4 productCard overflow-hidden">
        <div className="productDiscount">
          MAYO <span className="fs-4 p-0 m-0">50%</span>{" "}
          <span className="fw-bold"> OFF</span>
        </div>
        <div className="pricingContainer py-4 px-3 text-center">
          <span className="productSMSBadge rounded-pill px-3 py-1">
            AVISALE SMS
          </span>
          <div className="fs-5  mt-3">
            <span className="oldPrice d-flex align-items-center justify-content-center fw-semibold text-danger text-danger text-decoration-line-through">
              <span className="fw-semibold">$</span>500
            </span>
          </div>
          <div className="fs-5 mb-3">
            <span className="newPrice d-flex align-items-center justify-content-center fw-semibold text-">
              <span className="fw-semibold">$</span>250
            </span>
            <p>PAGO ÚNICO ANUAL</p>
          </div>
          <Link
            to="/compra/sms"
            className="btn btn-dark rounded-1 px-4 w-100 mt-3 fw-semibold"
          >
            CONTRATAR
          </Link>
        </div>
        <div className="features mt-4 px-3">
          <ul className="p-0">
            <li className="d-flex">
              <i className="bi bi-check2-circle text-success me-1"></i>1 CÓDIGO
              DE ACTIVACIÓN.
              <i
                className="bi bi-question-circle-fill ms-auto"
                ref={target}
                onClick={(e) =>
                  handleTip(
                    "EL CODIGO DE ACTIVACIÓN SE LE SOLICITARÁ AL MOMENTO DE REGISTRARSE COMO USUARIO.",
                    e
                  )
                }
              ></i>
            </li>
            <li className="d-flex">
              <i className="bi bi-check2-circle text-success me-1"></i>HASTA 3
              VEHÍCULOS.
              <i
                className="bi bi-question-circle-fill ms-auto"
                ref={target}
                onClick={(e) =>
                  handleTip("PODRÁ REGISTRAR DE 1 A 3 VEHÍCULOS.", e)
                }
              ></i>
            </li>
            <li className="d-flex">
              <i className="bi bi-check2-circle text-success me-1"></i>HASTA 3
              PROPIEDADES.
              <i
                className="bi bi-question-circle-fill ms-auto"
                ref={target}
                onClick={(e) =>
                  handleTip("PODRÁ REGISTRAR DE 1 A 3 PROPIEDADES.", e)
                }
              ></i>
            </li>
            <li className="d-flex">
              <i className="bi bi-check2-circle text-success me-1"></i>RECIBE
              HASTA 120 AVISOS.
              <i
                className="bi bi-question-circle-fill ms-auto"
                ref={target}
                onClick={(e) =>
                  handleTip(
                    "PODRÁ RECIBIR HASTA 120 AVISOS SMS EN TOTAL, INDEPENDIENTE DE LA PROPIEDAD O VEHÍCULO POR EL CUAL SEA NOTIFICADO.",
                    e
                  )
                }
              ></i>
            </li>
            <li className="d-flex">
              <i className="bi bi-check2-circle text-success me-1"></i>PANEL DE
              ADMINISTRACIÓN.
              <i
                className="bi bi-question-circle-fill ms-auto"
                ref={target}
                onClick={(e) =>
                  handleTip(
                    "TENDRÁ ACCESO A UN PANEL DE ADMINISTRACIÓN (DASHBOARD) DONDE PODRÁ VER EL ESTADO DE SU SERVICIO, HISTORIAL DE MENSAJES Y ADMINISTRAR SUS VEHÍCULOS Y PROPIEDADES.",
                    e
                  )
                }
              ></i>
            </li>
            <li className="d-flex">
              <i className="bi bi-check2-circle text-success me-1"></i>1 STICKER
              PARA VEHÍCULO.
              <i
                className="bi bi-question-circle-fill ms-auto"
                ref={target}
                onClick={(e) =>
                  handleTip(
                    "PODRÁ RETIRAR EN NUESTRA OFICINA SIN COSTO 1 STICKER PARA PEGAR EN SU VEHÍCULO. PERMITIRÁ A LOS USUARIOS SABER QUE SU VEHÍCULO CUENTA CON EL SERVICIO Y PARA QUE SIRVE, AUN SI NO LO CONOCEN.",
                    e
                  )
                }
              ></i>
            </li>
            <li className="d-flex">
              <i className="bi bi-check2-circle text-success me-1"></i>1 PLACA
              PARA PROPIEDAD.
              <i
                className="bi bi-question-circle-fill ms-auto"
                ref={target}
                onClick={(e) =>
                  handleTip(
                    "PODRÁ RETIRAR EN NUESTRA OFICINA SIN COSTO 1 PLAQUETA PARA COLOCAR EN LA PUERTA DE SU PROPIEDAD. PERMITIRÁ A LOS USUARIOS SABER QUE SU PROPIEDAD CUENTA CON EL SERVICIO Y PARA QUE SIRVE, AUN SI NO LO CONOCEN.",
                    e
                  )
                }
              ></i>
            </li>
          </ul>
        </div>
        <p className="productCardNote">
          PRECIO EXPRESADO EN PESOS URUGUAYOS IVA INCLUIDO.
        </p>
      </div>
      <Link
        className="text-center d-block text-primary fw-semibold mb-4"
        to="/clientes/dashboard"
      >
        VOLVER
      </Link>
      <CustomTooltip
        showTip={showTip}
        tollTipText={tollTipText}
        target={target}
      />
    </div>
  );
}

export default Products;

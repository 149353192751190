import { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import ConfirmModal from "./ConfirmModal";
import { toast } from "react-toastify";
import Loader from "../Loader";
import TextField from "@mui/material/TextField";

function IndexStepTwo() {
  const navigate = useNavigate();
  const [item, setItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [notifyTo, setNotifyTo] = useState(null);
  const [filtro, setFiltro] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();

  useEffect(() => {
    const getVehicle = async () => {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/api${
          params.plate
            ? `/vehiculos/avisar/${params.plate}`
            : `/propiedades/avisar/${params.code}`
        }`,
      });
      setTimeout(() => {
        if (!response.data.msg) return setItem(response.data);
        navigate("/avisar");
        toast.error(response.data.msg);
      }, 1000);
    };
    getVehicle();
    // eslint-disable-next-line
  }, []);

  const handleSend = async (msgId) => {
    setModalShow(false);
    setIsLoading(true);
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/registros`,
      data: {
        msgId,
        sendTo: item.plate ? item.plate : item.code,
        type: item.plate ? "vehicle" : "estate",
      },
    });
    //console.log(response);
    setIsLoading(false);
    if (response.status === 226) {
      navigate("/avisar");
      return toast.info(response.data.msg);
    } else {
      navigate("/avisar");
      toast.success("SE HA AVISADO AL PROPIETARIO. ¡GRACIAS!");
    }
  };

  return !isLoading ? (
    item ? (
      <div className="indexStepTwo mb-4 w-100">
        <h1 className="fs-4 text-center">
          MENSAJES HABILITADOS PARA{" "}
          {item.plate ? item.plate.toUpperCase() : item.code.toUpperCase()}
        </h1>
        <p className="text-dark text-center">SELECCIONE MENSAJE.</p>
        <hr />
        <form action="" /* onSubmit={handleSubmit} */>
          <TextField
            label={
              <span>
                <i className="bi bi-search fs-5 me-2"></i>PALABRA CLAVE, EJ:
                "LUCES".
              </span>
            }
            variant="standard"
            fullWidth
            inputProps={{ style: { fontSize: 20, letterSpacing: "3px" } }}
            className="mb-3"
            value={filtro}
            onChange={(e) => {
              setFiltro(e.target.value.trim().toUpperCase());
            }}
          />
        </form>
        <ul>
          {item.plate
            ? item.vehicle_presets
                .filter((msg) =>
                  msg.keywords.toString().includes(filtro.toLowerCase())
                )
                .map((preset) => (
                  <li
                    key={preset.id}
                    className="btn btn-outline-dark w-100 rounded-pill myButtonList"
                    onClick={() => {
                      setNotifyTo({
                        msgId: preset.id,
                        text: preset.text,
                        receiver: item.plate,
                      });
                      setModalShow(true);
                    }}
                  >
                    {preset.text.toUpperCase()}
                  </li>
                ))
            : item.estate_presets
                .filter((msg) =>
                  msg.keywords.toString().includes(filtro.toLowerCase())
                )
                .map((preset) => (
                  <li
                    key={preset.id}
                    className="btn btn-outline-dark w-100 rounded-pill myButtonList text-center"
                    onClick={() => {
                      setNotifyTo({
                        msgId: preset.id,
                        text: preset.text,
                        receiver: item.code,
                      });
                      setModalShow(true);
                    }}
                  >
                    {preset.text.toUpperCase()}
                  </li>
                ))}
        </ul>
        <button
          className="btn btn-dark w-100 rounded-pill confirmButton"
          onClick={() => navigate(-1)}
        >
          Volver
        </button>
        {notifyTo && (
          <ConfirmModal
            notifyTo={notifyTo}
            modalShow={modalShow}
            setModalShow={setModalShow}
            handleSend={handleSend}
            onHide={() => setModalShow(false)}
          />
        )}
      </div>
    ) : (
      <Loader />
    )
  ) : (
    <Loader />
  );
}

export default IndexStepTwo;

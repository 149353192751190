import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { useState } from "react";
registerLocale("es", es);
setDefaultLocale("es");

function MyDatePicker({ fromDate, setFromDate, toDate, setToDate }) {
  const [error, setError] = useState("");

  const checkFromDate = (date) => {
    const year = new Date(date).getFullYear();
    const month = new Date(date).getMonth();
    const day = new Date(date).getDate();
    const today = new Date();
    if (today.getFullYear() === year && today.getMonth() < month) {
      setError("Fecha de inicio no puede ser mayor a la fecha actual.");
      setFromDate(today);
    } else if (today.getMonth() === month && today.getDate() < day) {
      setError("Fecha de inicio no puede ser mayor a la fecha actual.");
      setFromDate(today);
    } else {
      setError("");
      setFromDate(date);
    }
  };

  const checkToDate = (date) => {
    const month = new Date(date).getMonth();
    const day = new Date(date).getDate();
    const initialMonth = new Date(fromDate).getMonth();
    const initialDay = new Date(fromDate).getDate();

    const today = new Date();
    if (month < initialMonth) {
      setError(
        "Fecha de cierre no puede ser mayor a la fecha actual ni menor a la fecha inicial."
      );
      setToDate(today);
    } else if (month >= initialMonth && day < initialDay) {
      setError(
        "Fecha de cierre no puede ser mayor a la fecha actual ni menor a la fecha inicial2."
      );
      setToDate(today);
    } else if (month >= today.getMonth() && day > today.getDate()) {
      setError(
        "Fecha de cierre no puede ser mayor a la fecha actual ni menor a la fecha inicial3."
      );
      setToDate(today);
    } else {
      setError("");
      setToDate(date);
    }
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <div>
        <div className="d-flex">
          <DatePicker
            className="w-100 rounded text-center border shadow-lg py-2 bg-light"
            dateFormat="P"
            selected={fromDate}
            onSelect={(date) => checkFromDate(date)}
            onChange={(date) => setFromDate(date)}
          />
          <span className="fs-5 mx-2">a</span>
          <DatePicker
            className="w-100 rounded text-center border shadow-lg py-2"
            dateFormat="P"
            selected={toDate}
            onSelect={(date) => checkToDate(date)}
            onChange={(date) => setToDate(date)}
          />
        </div>
      </div>
      {error && <p className="text-danger">{error}</p>}
    </div>
  );
}

export default MyDatePicker;

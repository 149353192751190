import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import DeleteModal from "./DeleteModal";

function Vehicles() {
  const [customer, setCustomer] = useState(null);
  const user = useSelector((state) => state.user);
  const [modalShow, setModalShow] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    const getCustomer = async () => {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/api/clientes/profile`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setCustomer(response.data);
    };
    getCustomer();
    // eslint-disable-next-line
  }, []);

  const handleShowModalDelete = (item) => {
    setItemToDelete(item);
    setModalShow(true);
  };

  return customer ? (
    <div className="d-flex flex-column customerVehicles w-100">
      <h1>VEHÍCULOS</h1>
      <hr />
      {!customer.vehicles.length && (
        <p className="text-dark">AUN NO HA REGISTRADO VEHÍCULOS</p>
      )}
      {customer.vehicles.length > 0 && (
        <p className="text-dark text-center">
          REGISTRADOS: <strong>{customer.vehicles.length}</strong> DE{" "}
          <strong>{customer.totalVehicles}</strong> DISPONIBLES.
        </p>
      )}
      {customer.vehicles.length === customer.totalVehicles && (
        <p className="text-dark fs-6 text-center">
          CONTACTENOS VIA WHATSAPP O EMAIL POR MÁS INFORMACIÓN.
        </p>
      )}
      {customer.vehicles.length < customer.totalVehicles && (
        <Link
          className="btn btn-success rounded-pill myNewItemButtonList"
          to={`/clientes/vehiculos/registrar`}
        >
          NUEVO VEHÍCULO
        </Link>
      )}
      {customer.vehicles.map((vehicle) => (
        <div key={vehicle.id} className="position-relative">
          <Link
            className="btn btn-outline-dark rounded-pill myButtonList"
            to={`/clientes/vehiculos/${vehicle.plate}`}
          >
            {vehicle.alias ? vehicle.alias.toUpperCase() : "MATRÍCULA"} |{" "}
            {vehicle.plate.toUpperCase()}
          </Link>
          <i
            className="bi bi-trash3 position-absolute fs-5 end-0 me-3"
            onClick={() => handleShowModalDelete(vehicle)}
          ></i>{" "}
        </div>
      ))}
      <hr />
      <Link
        className="text-center text-primary fw-semibold"
        to="/clientes/dashboard"
      >
        VOLVER
      </Link>
      <DeleteModal
        itemToDelete={itemToDelete}
        modalShow={modalShow}
        onHide={() => setModalShow(false)}
        customer={customer}
        setCustomer={setCustomer}
      />
    </div>
  ) : (
    <Loader />
  );
}

export default Vehicles;

import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import DeleteModal from "./DeleteModal";

function Estates() {
  const [customer, setCustomer] = useState(null);
  const user = useSelector((state) => state.user);
  const [modalShow, setModalShow] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    const getCustomer = async () => {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/api/clientes/profile`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setCustomer(response.data);
    };
    getCustomer();
    // eslint-disable-next-line
  }, []);

  const handleShowModalDelete = (item) => {
    setItemToDelete(item);
    setModalShow(true);
  };

  return customer ? (
    <div className="d-flex flex-column customerEstates w-100">
      <h1>PROPIEDADES</h1>
      <hr />
      {!customer.estates.length && (
        <p className="text-dark">AUN NO HA REGISTRADO PROPIEDADES</p>
      )}
      {customer.estates.length > 0 && (
        <p className="text-dark text-center">
          REGISTRADAS: <strong>{customer.estates.length}</strong> DE{" "}
          <strong>{customer.totalEstates}</strong> DISPONIBLES.
        </p>
      )}
      {customer.estates.length === customer.totalEstates && (
        <p className="text-dark text-center fs-6">
          CONTACTENOS VIA WHATSAPP O EMAIL POR MÁS INFORMACIÓN.
        </p>
      )}
      {customer.estates.length < customer.totalEstates && (
        <Link
          className="btn btn-success rounded-pill myNewItemButtonList"
          to={`/clientes/propiedades/registrar`}
        >
          NUEVA PROPIEDAD
        </Link>
      )}
      {customer.estates.map((estate) => (
        <div key={estate.id} className="position-relative">
          <Link
            className="btn btn-outline-dark rounded-pill myButtonList"
            to={`/clientes/propiedades/${estate.code}`}
          >
            {estate.alias ? estate.alias.toUpperCase() : "CÓDIGO "} |{" "}
            {estate.code.toUpperCase()}
          </Link>
          <i
            className="bi bi-trash3 position-absolute fs-5 end-0 me-3"
            onClick={() => handleShowModalDelete(estate)}
          ></i>{" "}
        </div>
      ))}
      <hr />
      <Link
        className="text-center text-primary fw-semibold"
        to="/clientes/dashboard"
      >
        VOLVER
      </Link>
      <DeleteModal
        itemToDelete={itemToDelete}
        modalShow={modalShow}
        onHide={() => setModalShow(false)}
        customer={customer}
        setCustomer={setCustomer}
      />
    </div>
  ) : (
    <Loader />
  );
}

export default Estates;

import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const SellerPrivateRoutes = ({ children }) => {
  const user = useSelector((state) => state.user);
  if (user && user.token && user.role === "seller") {
    return children ? children : <Outlet />;
  }
  return <Navigate to="/ventas/login" replace />;
};

export default SellerPrivateRoutes;

import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";

import { toast } from "react-toastify";
import Loader from "../Loader";
import { Spinner } from "react-bootstrap";

function OneEstate() {
  const [codeHasChanged, setCodeHasChanged] = useState(false);
  const [estate, setEstate] = useState(null);
  const [presets, setPresets] = useState([]);
  const [code, setCode] = useState("");
  const [alias, setAlias] = useState("");
  const [alternative_number, setAlternative_number] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [validatingLoader, setValidatingLoader] = useState(false);

  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    const getEstate = async () => {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/api/propiedades/${
          !codeHasChanged ? id : code
        }`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const response2 = await axios({
        url: `${process.env.REACT_APP_API_URL}/api/predefinidos_propiedades`,
        method: "GET",
      });
      setEstate(response.data);

      setCode(response.data.code.toUpperCase());
      setAlias(response.data.alias.toUpperCase());
      setAlternative_number(response.data.alternative_number);

      const presetsWithActiveProperty = response2.data.map((item) =>
        response.data.estate_presets.some((elem) => elem.id === item.id)
          ? { ...item, active: true }
          : { ...item, active: false }
      );

      setPresets(presetsWithActiveProperty.sort((a, b) => b.active - a.active));
    };
    getEstate();
    // eslint-disable-next-line
  }, []);

  const handlePresets = (item) => {
    setPresets(
      presets.map((preset) => {
        return preset.id !== item.id
          ? preset
          : { ...preset, active: !preset.active };
      })
    );
    setSubmitDisabled(false);
  };

  const handleAllPresets = () => {
    if (presets.every((preset) => preset.active)) {
      setPresets(presets.map((preset) => ({ ...preset, active: false })));
    } else {
      setPresets(presets.map((preset) => ({ ...preset, active: true })));
    }
    setSubmitDisabled(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const messages = [];
    for (const preset of presets) {
      if (preset.active) {
        messages.push(preset.id);
      }
    }

    if (messages.length === 0)
      return toast.error("DEBE HABILITAR AL MENOS 1 MENSAJE.");

    const requiredValues = [code];
    if (requiredValues.includes(""))
      return toast.error("DEBE INGRESAR CÓDIGO.");

    setValidatingLoader(true);

    const response = await axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/api/propiedades/${estate.id}`,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      data: { code, alias, alternative_number, messages },
    });
    if (response) {
      setValidatingLoader(false);
      toast.success(`SE HA ACTUALIZADO ${estate.alias.toUpperCase()}.`);
      navigate("/clientes/propiedades");
    }
  };

  return (
    <div className="d-flex flex-column">
      <h1 className="fs-1 text-center">PROPIEDAD</h1>
      {estate && presets.length ? (
        <div className="customerEstate w-100">
          <form action="" onSubmit={handleSubmit}>
            <TextField
              label={
                <span>
                  <i className="bi bi-buildings fs-4 me-2"></i>CÓDIGO
                </span>
              }
              value={code}
              variant="standard"
              fullWidth
              inputProps={{
                style: { fontSize: 20, letterSpacing: "3px" },
              }}
              className="my-3"
              onChange={(e) => {
                setCode(e.target.value.trim().toUpperCase());
                setSubmitDisabled(false);
                setCodeHasChanged(true);
              }}
            />

            <TextField
              label={
                <span>
                  <i className="bi bi-diamond-half fs-4 me-2"></i>ALIAS
                  (OPCIONAL)
                </span>
              }
              value={alias}
              variant="standard"
              fullWidth
              inputProps={{ style: { fontSize: 20, letterSpacing: "3px" } }}
              className="mb-3"
              onChange={(e) => {
                setAlias(e.target.value.trim().toUpperCase());
                setSubmitDisabled(false);
              }}
            />

            <TextField
              label={
                <span>
                  <i className="bi bi-phone-fill fs-4 me-2"></i>NUMERO
                  ALTERNATIVO (OPCIONAL)
                </span>
              }
              id="input-with-sx"
              variant="standard"
              type="number"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                style: { fontSize: 20, letterSpacing: "3px" },
              }}
              className="mb-5"
              value={alternative_number}
              fullWidth
              onChange={(e) => {
                setAlternative_number(e.target.value.trim());
                setSubmitDisabled(false);
              }}
            />
            <div className="d-flex justify-content-between generalListSwitch mb-2">
              <h2 className="fs-5 mb-0 pb-0 d-flex align-items-center">
                MOTIVOS DE AVISO
              </h2>
              <i
                className={
                  presets.every((preset) => preset.active)
                    ? "bi bi-check-circle-fill text-success d-flex align-items-center"
                    : "bi bi-circle text-secondary d-flex align-items-center"
                }
                onClick={handleAllPresets}
              ></i>
            </div>
            {presets.map((preset) => (
              <div
                key={preset.id}
                className="d-flex align-items-center position-relative"
              >
                <button
                  type="button"
                  className={
                    preset.active
                      ? "btn btn-outline-dark rounded-pill myButtonList"
                      : "btn btn-outline-secondary text-decoration-line-through text-secondary myButtonList rounded-pill"
                  }
                >
                  {preset.text.toUpperCase()}
                </button>
                <i
                  onClick={(e) => handlePresets(preset)}
                  className={
                    preset.active
                      ? "bi bi-check-circle-fill text-success listSwitch"
                      : "bi bi-circle text-secondary listSwitch"
                  }
                ></i>
              </div>
            ))}

            {validatingLoader ? (
              <button
                id="login-btn"
                type="button"
                className="btn btn-dark rounded-pill w-100 mt-3 shadow confirmButton"
              >
                <span className="d-flex align-items-center justify-content-center">
                  <Spinner animation="grow" variant="light" className="me-3" />
                  GUARDANDO...
                </span>
              </button>
            ) : (
              <button
                type="submit"
                id="login-btn"
                disabled={submitDisabled}
                className="btn btn-dark rounded-pill w-100 mt-3 shadow confirmButton"
              >
                GUARDAR CAMBIOS
              </button>
            )}
          </form>
          <hr />
          <div className="text-center mb-4">
            <Link className="text-primary fw-semibold" to="/clientes/dashboard">
              VOLVER
            </Link>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default OneEstate;

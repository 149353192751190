import React from "react";

function PublicLargeMenu({ toggleShowOffcanvas, show }) {
  return (
    <div id="largeMenuToggleContainer" className=" d-none d-md-flex">
      <i
        className={`bi bi-grip-horizontal largeMenuToggleButton ${
          show && "active"
        }`}
        onClick={toggleShowOffcanvas}
      ></i>
    </div>
  );
}
export default PublicLargeMenu;

import { Link } from "react-router-dom";
import CustomAccordion from "../CustomAccordion";
import FaqExampleModal from "../public/FaqExamplesModal";
import { useState } from "react";

function Faq() {
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const handleShow = (title, text, image) => {
    setModalData({ title, text, image });
    setModalShow(true);
  };
  return (
    <div id="faq" className="w-100 pb-4">
      <h1 className="fs-1 text-center">PREGUNTAS FRECUENTES</h1>
      <hr />
      <h3 className="text-center fs-3">GENERALES</h3>
      <CustomAccordion
        number={1}
        question="¿Por qué avisale.uy?"
        text={
          <div>
            <p>
              A muchos nos ha pasado llegar a nuestro vehículo o propiedad y
              encontrarnos con situaciones como no poder arrancar por haber
              dejado luces encendidas, encontrarnos con un vidrio roto, haber
              quedado mal estacionado u obstruyendo una salida, encontrar fugas
              de gases o líquidos, etc.
            </p>
            <p>
              En muchos otros casos hemos visto vehículos o propiedades que
              necesitan atención, pero no sabemos cómo contactar a su
              responsable para informarle de la situación.
            </p>
            <p>
              Es aquí donde nuestra aplicación se convierte en la solución
              perfecta para avisar sin ccosto a dicha persona sobre cualquier
              necesidad que su propiedad o vehículo presente, como las antes
              mencionadas.
            </p>
          </div>
        }
      />

      <CustomAccordion
        number={2}
        question="¿Cómo funciona nuestra app?"
        text={
          <div>
            <p>
              Cada usuario registrado tendrá acceso a un panel donde podrá ver
              información de su servicio, agregar, modificar y quitar hasta tres
              vehículos y/o tres propiedades, con la posibilidad de aumentar
              esta cantidad.
            </p>
            <p>
              Las personas que avisen no necesitan conocer ningún dato de la
              persona que será notificada. Solo deberán proporcionar la
              matrícula del vehículo o el código de la propiedad para avisar de
              cualquier evento que requiera atención. Este servicio no tendrá
              costo para quien avisa, lo que significa que cualquier persona con
              acceso a internet puede usarlo sin preocuparse por los gastos.
            </p>
            <p>
              Al momento de avisar, solo se podrá enviar uno de los mensajes
              existentes en una lista predefinida por la persona que registró el
              vehículo o propiedad. Lo cual asegura que el servicio no podrá ser
              utilizado para publicidad, motivos personales u otros que puedan
              causar algún tipo de molestia.
            </p>
            <p>
              Una vez confirmado el envío, el dueño recibirá la correspondiente
              notificación en su celular. <br />
              Ejemplo de notificación:
              <br />
              <span className="fw-semibold">
                MATRICULA/CODIGO: SBS1234 (COCHE MAMA) - MOTIVO: LUCES
                ENCENDIDAS
              </span>
              .
            </p>
            <p>
              Entendemos la importancia de que la notificación llegue a su
              destino, por lo que implementamos el envío por dos proveedores
              diferentes. De esta forma logramos que si uno de ellos presenta
              algún inconveniente, el mensaje se envíe por el otro. En
              condiciones normales se recibirán ambos mensajes.(Este es un
              mecanísmo interno para reducir las posibilidades de fallo, solo se
              contabilizará un mensaje recibido.)
            </p>
          </div>
        }
      />

      <CustomAccordion
        number={3}
        question="¿Qué es el número alternativo y cómo funciona?"
        text={
          <div>
            <p>
              Es un número de celular que recibirá las mismas notificaciones que
              tu cuando seas avisado.
            </p>
            <p>
              Su objetivo lograr incrementar las posibilidades de que recibas el
              aviso lo antes posible. Incluso en caso de que no hayas notado la
              notificación en tu celular, la persona cuyo número hayas ingresado
              como alternativo podrá avisarte.
            </p>
            <p>
              Es opcional y puede ser diferente para cada vehículo o propiedad
              que registres. Si deseas ingresarlo, se puede hacer durante el
              registro.
            </p>
            <p>
              Se podrá modificar o borrar tantas veces como desees en la opción{" "}
              <i className="bi bi-car-front fs-6 mx-1"></i>
              VEHÍCULOS de tu panel (DASHBOARD) o en menú{" "}
              <i className="bi bi-list fs-2 text-white px-1 mx-1 bg-dark"></i>{" "}
              opción <i className="bi bi-car-front fs-5 mx-1"></i>MIS VEHÍCULOS.
            </p>
            <p>
              <span className="fw-semibold">Nota: </span>
              En caso de tener ingresado un número alternativo, si alguien te
              envía un aviso se contabilizarán cuatro mensajes, dos a tu celular
              y dos al alternativo.
            </p>
          </div>
        }
      />

      <CustomAccordion
        number={4}
        question="¿Cómo avisar a un propietario?"
        text={
          <div>
            <p>
              Al ingresar en nuestro sitio, la página principal es la de envío
              de avisos. También se puede ir a menú{" "}
              <i className="bi bi-list fs-2 text-white px-1 mx-1 bg-dark"></i> y
              elegir la opción
              <i className="bi bi-bell fs- mx-1"></i>AVISAR, o presionar click
              sobre el logo de <span className="fw-semibold">avisale.uy</span>.
            </p>
            <p>Una vez allí solo debes seguir los siguientes pasos:</p>
            <ol>
              <li>Ingresar código o mátricula.</li>
              <li>Seleccionar mensaje que desea enviar.</li>
              <li>Confirmar envío.</li>
            </ol>
            <p>
              Finalmente en la parte superior de la pantalla aparecerá una
              notificación indicando que el propietario fue avisado.
            </p>
            <p>
              Eso es todo,{" "}
              <span className="fw-semibold">
                ¡En pocos segundos puedes hacer mucho mejor el día de alguien!
              </span>
            </p>
          </div>
        }
      />

      <CustomAccordion
        number={5}
        question="¿Cómo funciona el sistema de referidos?"
        text={
          <div>
            <p>
              Todas las personas registradas pueden acceder al beneficio de
              referidos.
            </p>
            <p>
              Por cada 5 personas que recomiendes, obtendrás sin costo un año de
              suscripción adicional al servicio.
            </p>
            <p>
              Al recomendar debes facilitar tu{" "}
              <span className="fw-semibold">Identificador</span> al nuevo
              usuario. (Si no sabes cual es, puedes encontrarlo en panel{" "}
              <span className="fw-semibold mx-1">(DASHBOARD)</span> /{" "}
              <span className="fw-semibold mx-1">MIS DATOS</span>).
            </p>
            <p>
              Al momento de realizar el registro, el nuevo usuario debe
              seleccionar la opción{" "}
              <span className="fw-semibold">RECOMENDADO</span> en el campo donde
              pregunta{" "}
              <span className="fw-semibold">¿CÓMO CONOCIÓ AVISALE.UY?</span> e
              ingresar tu <span className="fw-semibold">Identificador</span>.
            </p>
            <p>
              Una vez confirmado el registro, podrás verlo reflejado en la
              cantidad de referidos en tu panel{" "}
              <span className="fw-semibold mx-1">(DASHBOARD)</span> /
              <span className="fw-semibold ms-1">REFERIDOS</span>.
            </p>
          </div>
        }
      />

      <CustomAccordion
        number={6}
        question="¿Mis datos están seguros?"
        text={
          <div>
            <p>
              Cumplimos con la Ley de Protección de Datos Personales (Ley N°
              18.331) y su decreto reglamentario (Decreto N° 414/009) en
              Uruguay, donde se establecen los principios y procedimientos para
              el tratamiento de datos personales, incluyendo la recopilación,
              almacenamiento, uso, transferencia y eliminación de dichos datos.
            </p>
            <p>
              La aplicación cuenta con varios mecanísmos de seguridad y la
              opción de cerrar la sesión en dispositivos remotos en caso de
              perdida o robo.
            </p>
            <p>
              Nuestro empresa <span className="fw-semibold">jamás</span>{" "}
              expondrá los datos de ningún usuario a terceros a no ser que por
              ley se le exija.
            </p>
          </div>
        }
      />

      <CustomAccordion
        number={7}
        question="¿Cómo saben las personas que pueden avisarme?"
        text={
          <div>
            <p>
              Al contratar el servicio obtendrás{" "}
              <span className="fw-semibold">sin costo un sticker</span> para
              pegar en tu vehículo (
              <span
                className="text-primary"
                onClick={() =>
                  handleShow(
                    "STICKER AUTO",
                    <ul>
                      <li>Material: Vinilo (No Papel).</li>
                      <li>Tinta: Solvente, resiste intemperie.</li>
                      <li>Medidas: 13 cm x 6.5 cm (L x A).</li>
                      <li>Fijación: Adhesivo.</li>
                    </ul>,
                    "auto"
                  )
                }
              >
                ver ejemplo
              </span>
              ) y <span className="fw-semibold">una placa</span> para puerta con
              el código de propiedad, ideal para apartamentos o puertas de
              entrada de facil acceso para otras personas. (
              <span
                className="text-primary"
                onClick={() =>
                  handleShow(
                    "PLACA PARA PUERTA",
                    <ul>
                      <li>Material: PVC (2mm).</li>
                      <li>Lámina: Vinilo.</li>
                      <li>Tinta: Solvente, resiste intemperie.</li>
                      <li>Medidas: 6 cm x 4 cm x 2mm (L x A x P).</li>
                      <li>Fijación: Cinta doble faz (incluída).</li>
                    </ul>,
                    "puerta"
                  )
                }
              >
                ver ejemplo
              </span>
              )
            </p>
            <p>
              Ofrecemos también con costo adicional, placas exteriores grandes
              (ideales para propiedades cuya puerta principal o frente están
              alejados de las personas que podrían avisarte, logrando una mejor
              visibilidad a mayor distancia. (
              <span
                className="text-primary"
                onClick={() =>
                  handleShow(
                    "PLACA EXTERIOR",
                    <ul>
                      <li>Material: PVC.</li>
                      <li>Lámina: Vinilo.</li>
                      <li>Tinta: Solvente, resiste intemperie.</li>
                      <li>Medidas: 27 cm x 22cm (L x A).</li>
                      <li>Fijación: Tornillos (set incluído).</li>
                    </ul>,
                    "exterior"
                  )
                }
              >
                ver ejemplo
              </span>
              )
            </p>
            <p>
              Los stickers y placas pueden retirarse en nuestra oficina, próximo
              a facultad de arquitectura, o pueden ser enviados por el servicio
              privado de tu preferencia (debes abonar el costo del envío), nos
              encargamos del despacho en la agencia sin costo.
            </p>
            <p>
              Puedes opcionalmente comprar tantos stickers y placas como
              necesites, para hacerlo puedes dirigirte a menú{""}
              <i className="bi bi-list fs-2 text-white px-1 mx-1 bg-dark"></i> y
              elegir la opción <i className="bi bi-bag-fill fs-5 mx-1"></i>
              PRODUCTOS.
            </p>
            <p>
              Estos elementos le facilitarán a cualquier persona la información
              necesaria para avisarte, aun si la misma no conoce el servicio.
            </p>
            <p>
              Adicional a lo antes mencionado, trabajamos y seguiremos
              trabajando en diversas campañas de publicidad.
            </p>
            <p>
              Te recomendamos también leer "¿Cómo funciona el sitema de
              referidos?". Este beneficio hace posible la extensión de tu
              suscripción sin costo y contribuye fuertemente a la difusión.
            </p>
          </div>
        }
      />

      <CustomAccordion
        number={8}
        question="¿Si ya fui avisado, pueden avisarme otras personas?"
        text={
          <div>
            <p>
              Si ya fuiste avisado y otra persona intenta avisarte dentro de la
              siguiente media hora, recibirá un mensaje indicando que ya fuiste
              notificado recientemente. Evitando de esta manera reiterados
              mensajes por un mismo motivo y el descuento de los mismos.
            </p>
            <p>
              Pasada la media hora se habilitarán automáticamente las
              notificaciónes, repitiendose el proceso anterior en caso de aviso.
              De esta manera si no has notado el mensaje anterior, podrás
              recibir uno nuevo.
            </p>
          </div>
        }
      />

      <CustomAccordion
        number={9}
        question="¿Que sucede si alguien intenta envíos masivos?"
        text={
          <div>
            <p>
              Si se intenta notificar a un mismo vehículo, por el mecanísmo
              mencionado en la pregunta anterior, la persona que lo intente solo
              podrá enviar un mensaje.
            </p>
            <p>
              Si intenta notificar a diferentes vehículos, al tercer intento
              dentro de los últimos 30 minutos, su dispositivo será bloqueado
              para envíos y será notificado de dicho bloqueo a causa de
              reiterados intentos. Informando también que se hará la denuncia
              correspondiente en caso de repetirse la situación.
            </p>
          </div>
        }
      />

      <h3 className="text-center fs-3 my-3">NUEVOS REGISTROS</h3>

      <CustomAccordion
        number={20}
        question="¿Cómo contratar el servicio?"
        text={
          <div>
            <p>
              Para contratar el servicio, ve a menú
              <i className="bi bi-list fs-2 text-white px-1 mx-1 bg-dark"></i> y
              elege la opción <i className="bi bi-bag-fill fs-5 mx-1"></i>
              PRODUCTOS, allí podrás elegir la opción que más se ajuste a tus
              necesidades.
            </p>
            <p>
              Para continuar presiona{" "}
              <span className="bg-dark mx-1 px-3 py-1 rounded-1 text-white fs-6">
                CONTRATAR
              </span>{" "}
              y elige una forma de pago.
            </p>
            <p>
              Sigue los pasos que allí se detallan y al finalizar la compra te
              brindaremos un código de activación. Es importante que guardes
              este código, será necesario para tu registro de usuario.
            </p>
            <p>Para registrarte, te invitamos a ver la siguiente pregunta.</p>
          </div>
        }
      />

      <CustomAccordion
        number={21}
        question="¿Cómo registrar mi usuario?"
        text={
          <div className="text-break">
            <p>
              Ve a menú{" "}
              <i className="bi bi-list fs-2 text-white px-1 bg-dark"></i> y
              elige la opción <i className="bi bi-person-plus fs-5 mx-1"></i>
              NUEVO USUARIO.
            </p>
            <p>
              Ingresa el código de activación que te brindamos al finalizar la
              compra y presiona{" "}
              <span className="bg-dark px-3 py-1 rounded-1 text-white fs-6 faqButton">
                VALIDAR CÓDIGO
              </span>
              .
            </p>
            <p>
              Ingresa tus datos en el formulario y presiona{" "}
              <span className="bg-dark px-3 py-1 rounded-1 text-white fs-6 faqButton">
                CONFIRMAR REGISTRO
              </span>
              .
            </p>
            <p>
              <span className="fw-semibold">Nota: </span>
              Todos los campos en este formulario son obligatorios. Si llegaste
              a avisale.uy recomendado por otro usuario, deberás ingresar el{" "}
              <span className="fw-semibold">Identificador</span> del mismo en el
              campo que lo solicita.
            </p>
          </div>
        }
      />

      <CustomAccordion
        number={22}
        question="¿Cómo registrar un vehículo?"
        text={
          <div className="text-break">
            <p>
              En tu panel (DASHBOARD) dirígite a{" "}
              <span className="bg-dark px-3 py-1 rounded-pill text-white fs-6 faqButton">
                <i className="bi bi-car-front fs-6 mx-1"></i>VEHÍCULOS
              </span>{" "}
              o ve a menú{" "}
              <i className="bi bi-list fs-2 text-white px-1 mx-1 bg-dark"></i> y
              elige la opción <i className="bi bi-car-front fs-5 mx-1"></i>MIS
              VEHÍCULOS.
            </p>
            <p>
              Presiona{" "}
              <span className="bg-success mx-1 px-3 py-1 rounded-pill text-white fs-6 faqButton">
                NUEVO VEHÍCULO
              </span>{" "}
              y sigue los pasos que allí se indican.
            </p>
            <p>
              Una vez completado el formulario, presiona{" "}
              <span className="bg-dark px-3 py-1 rounded-1 text-white fs-6 faqButton">
                CONFIRMAR REGISTRO
              </span>
              .
            </p>
            <p>
              Eso es todo,{" "}
              <span className="fw-semibold">
                ¡Ya puedes experimentar la tranquilidad de saber que cualquier
                persona podrá avisarte si algo sucede con tu vehículo!
              </span>
            </p>
          </div>
        }
      />

      <CustomAccordion
        number={23}
        question="¿Cómo registrar una propiedad?"
        text={
          <div className="text-break">
            <p>
              En tu panel (DASHBOARD) dirígite a{" "}
              <span className="bg-dark px-3 py-1 rounded-pill text-white fs-6 faqButton">
                <i className="bi bi-buildings fs-6 me-1"></i>PROPIEDADES
              </span>{" "}
              o ve a menú{" "}
              <i className="bi bi-list fs-2 text-white px-1 mx-1 bg-dark"></i> y
              elige la opción <i className="bi bi-buildings fs-5"></i>MIS
              PROPIEDADES.
            </p>
            <p>
              Presiona{" "}
              <span className="bg-success px-3 py-1 rounded-pill text-white fs-6 faqButton">
                NUEVA PROPIEDAD
              </span>{" "}
              y sigue los pasos que allí se indican.
            </p>
            <p>
              Una vez completado el formulario, presiona el botón{" "}
              <span className="bg-dark px-3 py-1 rounded-1 text-white fs-6 faqButton">
                CONFIRMAR REGISTRO
              </span>
              .
            </p>
            <p>
              Eso es todo,{" "}
              <span className="fw-semibold">
                ¡Ya puedes experimentar la tranquilidad de saber que cualquier
                persona podrá avisarte si algo sucede en tu propiedad!
              </span>
            </p>
          </div>
        }
      />

      <h3 className="text-center fs-3 my-3">USUARIOS REGISTRADOS</h3>

      <CustomAccordion
        number={40}
        question="¿Cómo entrar a mi panel (DASHBOARD)?"
        text={
          <div className="text-break">
            <p>
              Ve a menú{" "}
              <i className="bi bi-list fs-2 text-white px-1 mx-1 bg-dark"></i> y
              elige la opción
              <i className="bi  bi-person-check fs-5 mx-1"></i>INICIAR SESIÓN.
            </p>
            <p>
              Ingresa el teléfono y contraseña que utilizaste para el registro
              de tu usuario.
              <br />
            </p>
            <p>
              Presiona el botón{" "}
              <span className="bg-dark px-3 py-1 rounded-1 text-white fs-6 faqButton">
                INICIAR SESIÓN{" "}
              </span>
              .
            </p>
            <p>
              Eso es todo,
              <span className="fw-semibold">
                {" "}
                ¡Disfruta de tu PANEL (DASHBOARD)!
              </span>
            </p>
          </div>
        }
      />

      <CustomAccordion
        number={41}
        question="¿Cómo modificar un vehículo?"
        text={
          <div className="text-break">
            <p>
              En tu panel (DASHBOARD) dirígite a{" "}
              <span className="bg-dark px-3 py-1 rounded-pill text-white fs-6 faqButton">
                <i className="bi bi-car-front fs-6 mx-1"></i>VEHÍCULOS
              </span>{" "}
              o ve a menú{" "}
              <i className="bi bi-list fs-2 text-white px-1 mx-1 bg-dark"></i> y
              elige la opción
              <i className="bi bi-car-front fs-5 mx-1"></i>MIS VEHÍCULOS.
            </p>
            <p>
              Presiona sobre el vehículo que deseas modificar ej:{" "}
              <span className="bg-light border border-dark px-3 py-1 rounded-pill text-dark fs-6 faqButton">
                COCHE ANA | SAB1122
              </span>
              .
            </p>
            <p>
              Allí podrás modificar matrícula, alias (opcional), número
              alternativo (opcional) y habilitar o deshabilitar los mensajes que
              aparecerán al momento de avisarte.
            </p>
            <p>
              Una vez realizados los cambios deseados, presiona{" "}
              <span className="bg-dark me-1 px-3 py-1 rounded-1 text-white fs-6 faqButton">
                GUARDAR CAMBIOS
              </span>
              .
            </p>
            <p>
              <span className="fw-semibold">Notas: </span> <br />
              Si deseas omitir los cambios presiona{" "}
              <span className="text-primary">VOLVER</span>. <br /> Si la
              matrícula fue modificada, los usuarios que avisen lo deberán hacer
              a esta última ingresada.
            </p>
          </div>
        }
      />

      <CustomAccordion
        number={42}
        question="¿Cómo modificar una propiedad?"
        text={
          <div className="text-break">
            <p>
              En tu panel (DASHBOARD) dirígite a{" "}
              <span className="bg-dark px-3 py-1 rounded-pill text-white fs-6 faqButton">
                <i className="bi bi-buildings fs-6"></i>PROPIEDADES
              </span>{" "}
              o ve a menú{" "}
              <i className="bi bi-list fs-2 text-white px-1 bg-dark"></i> y
              elige la opción{""}
              <i className="bi bi-buildings fs-5 mx-1"></i>MIS PROPIEDADES.
            </p>
            <p>
              Presiona sobre la propiedad que deseas modificar ej:{" "}
              <span className="bg-light border border-dark px-3 py-1 rounded-pill text-dark fs-6 faqButton">
                CASA LUIS | D3JDK3
              </span>
              .
            </p>
            <p>
              Allí podrás modificar, alias (opcional), número alternativo
              (opcional) y habilitar o deshabilitar los mensajes que aparecerán
              al momento de avisarte.
            </p>
            <p>
              Una vez realizados los cambios deseados, presiona{" "}
              <span className="bg-dark px-3 py-1 rounded-1 text-white fs-6 faqButton">
                GUARDAR CAMBIOS
              </span>
              .
            </p>
            <p>
              <span className="fw-semibold">Notas: </span> <br />
              Si deseas omitir los cambios presiona{" "}
              <span className="text-primary">VOLVER</span>. <br /> El código de
              la propiedad no se puede modificar, éste se genera automáticamente
              al momento de su registro.
            </p>
          </div>
        }
      />

      <CustomAccordion
        number={43}
        question="¿Cómo modificar mis datos?"
        text={
          <div className="text-break">
            <p>
              En tu panel (DASHBOARD) dirígite a{" "}
              <span className="bg-dark px-3 py-1 rounded-pill text-white fs-6 faqButton">
                <i className="bi bi-person-lines-fill fs-6 mx-2"></i>MIS DATOS
              </span>{" "}
              o ve a menú{" "}
              <i className="bi bi-list fs-2 text-white px-1 mx-1 bg-dark"></i> y
              elige la opción{""}
              <i className="bi bi-person-lines-fill fs-5 mx-1"></i>MIS DATOS.
            </p>
            <p>
              Allí podrás modificar, nombre, email y número de teléfono (es el
              número al que llegarán las notificaciones).
            </p>
            <p>
              Una vez realizados los cambios deseados, presiona{" "}
              <span className="bg-dark px-3 py-1 rounded-1 text-white fs-6 faqButton">
                GUARDAR CAMBIOS
              </span>
              .
            </p>
            <p>
              <span className="fw-semibold">Notas: </span> <br />
              Si deseas omitir los cambios presiona{" "}
              <span className="text-primary">VOLVER</span>. <br /> Desde aquí
              también es posible cambiar la contraseña, si deseas cambiarla te
              invitamos a ver la siguiente pregunta.
            </p>
          </div>
        }
      />

      <CustomAccordion
        number={44}
        question="¿Cómo cambiar mi contraseña?"
        text={
          <div className="text-break">
            <p>
              En tu panel (DASHBOARD) dirígite a{" "}
              <span className="bg-dark px-3 py-1 rounded-pill text-white fs-6 faqButton">
                <i className="bi bi-person-lines-fill fs-6 mx-2"></i>MIS DATOS
              </span>{" "}
              o ve a menú{" "}
              <i className="bi bi-list fs-2 text-white px-1 mx-1 bg-dark"></i> y
              elige la opción{""}
              <i className="bi bi-person-lines-fill fs-5 mx-1"></i>MIS DATOS.
            </p>
            <p>
              Presiona el botón{" "}
              <span className="bg-light border border-dark px-3 py-1 rounded-pill text-dark fs-6 faqButton">
                CAMBIAR CONTRASEÑA
              </span>
              .
            </p>
            <p>
              Ingresa la nueva contraseña y presiona el botón{" "}
              <span className="bg-dark px-3 py-1 rounded-1 text-white fs-6 faqButton">
                GUARDAR CAMBIOS
              </span>
              .
            </p>
            <p>
              <span className="fw-semibold">Notas: </span> <br />
              Si deseas omitir los cambios presiona{" "}
              <span className="text-primary">VOLVER</span>. <br /> Si iniciaste
              el cambio de contraseña deseas omitirlo y continuar modificando
              otros datos sin que esta sea afectada, presiona el botón{" "}
              <span className="bg-light border border-dark px-3 py-1 rounded-pill text-dark fs-6 faqButton">
                NO CAMBIAR CONTRASEÑA
              </span>
              .
            </p>
          </div>
        }
      />

      <CustomAccordion
        number={45}
        question="¿Para qué es y dónde encuentro mi Identificador?"
        text={
          <div className="text-break">
            <p>
              El <span className="fw-semibold">Identificador</span> es un valor
              único de cada usuario. Este se utiliza para el sistema de
              referidos con el fin de poder brindar una referencia sencilla a la
              persona recomenda. Sin la necesidad de compartir con la misma
              ningún dato personal.
            </p>

            <p>
              Para encontrarlo, en el panel (DASHBOARD) dirígite a{" "}
              <span className="bg-dark px-3 py-1 rounded-pill text-white fs-6 faqButton">
                <i className="bi bi-person-lines-fill fs-6 mx-2"></i>MIS DATOS
              </span>{" "}
              o ve a menú{" "}
              <i className="bi bi-list fs-2 text-white px-1 mx-1 bg-dark"></i> y
              elige la opción{" "}
              <i className="bi bi-person-lines-fill fs-5 mx-1"></i>MIS DATOS.
            </p>
            <p>
              Una vez allí encontrarás en la parte superior algo así:{" "}
              <span className="border p-1 faqButton">
                IDENTIFICADOR:
                <span className="fw-semibold">
                  {" "}
                  XXXXXX (la cantidad de cifras puede variar)
                </span>
              </span>
              .
            </p>
          </div>
        }
      />

      <CustomAccordion
        number={46}
        question="¿Para qué es y dónde encuentro mi Código de Activación?"
        text={
          <div className="text-break">
            <p>
              El <span className="fw-semibold">Código de Activación</span> es un
              valor único de cada usuario. Este será útil para gestiones sobre
              tu servicio y será necesario en caso de que olvides tu contraseña
              para restablecerla.
            </p>

            <p>
              Para encontrarlo, en el panel (DASHBOARD) dirígite a{" "}
              <span className="bg-dark px-3 py-1 rounded-pill text-white fs-6 faqButton">
                <i className="bi bi-person-lines-fill fs-6 mx-2"></i>MIS DATOS
              </span>{" "}
              o ve a menú{" "}
              <i className="bi bi-list fs-2 text-white px-1 mx-1 bg-dark"></i> y
              elige la opción <i className="bi bi-person-lines-fill fs-5"></i>
              MIS DATOS.
            </p>
            <p>
              Una vez allí, encontrarás en la parte superior algo así:{" "}
              <span className="border p-1 faqButton">
                CÓDIGO DE ACTIVACIÓN:
                <span className="fw-semibold"> ABC1</span>
              </span>
              .
            </p>
          </div>
        }
      />

      <CustomAccordion
        number={47}
        question="¿Que hago en caso de pérdida o robo de mi teléfono?"
        text={
          <div className="text-break">
            <p>
              Recomendamos lo antes posible desconectar el servicio en todos los
              dispositivos.
            </p>
            <p>
              En tu panel (DASHBOARD), presiona sobre{" "}
              <i className="bi bi-three-dots fs-2 mx-1 border p-1"></i> próximo
              a la esquina inferior derecha. Se desplegará un menú en el que
              debes presionar
              <i className="bi bi-door-open-fill fs-5 mx-1"></i>
              CERRAR SESIÓN EN TODOS LOS DISPOSITIVOS.
            </p>
            <p>
              Si tu contraseña estaba guardada en el dispositivo perdido,
              recomendamos que ingreses una nueva contraseña en{" "}
              <i className="bi bi-person-lines-fill fs-5"></i>
              MIS DATOS. En ese caso también se cerrará automáticamente la
              sesión en todos los dispositivos.
            </p>
          </div>
        }
      />

      <CustomAccordion
        number={44}
        question="¿Cómo restablecer contraseña?"
        text={
          <div className="text-break">
            <p>
              Si olvidaste tu contraseña dirígete a menú{" "}
              <i className="bi bi-list fs-2 text-white px-1 mx-1 bg-dark"></i> y
              elige la opción
              <i className="bi  bi-person-check fs-5 mx-1"></i>INICIAR SESIÓN.
            </p>
            <p>
              Una vez allí presiona{" "}
              <span className="text-primary">OLVIDÉ MI CONTRASEÑA</span>.
            </p>
            <p>
              Ingresa tu teléfono y código de activación. Si no recuerdas tu
              código de activación presiona{" "}
              <span className="text-primary">
                NO RECUERDO CÓDIGO DE ACTIVACIÓN
              </span>{" "}
              y lo recibirás en breve en tu celular.
            </p>
            <p>
              Presiona{" "}
              <span className="bg-dark px-3 py-1 rounded-1 text-white fs-6 faqButton">
                RESTABLECER
              </span>{" "}
              para finalizar y recibirás en tu celular una nueva clave.
            </p>
            <p>
              Ya puedes iniciar sesión con tu número de teléfono y la clave
              recibida.
            </p>
            <p>
              Recuerda que puedes cambiar tu contraseña cuando desees en{" "}
              <i className="bi bi-person-lines-fill fs-5 mx-1"></i>MIS DATOS.
            </p>
          </div>
        }
      />
      <p className="py-3">
        Si tienes alguna duda que no fue posible evacuar, nos puedes contactar
        en menú <i className="bi bi-list fs-2 text-white px-1 mx-1 bg-dark"></i>{" "}
        opción <i className="bi bi-envelope-at fs-5 mx-1"></i>CONTACTO,
        escribirnos presionando el icono de Whatsapp{" "}
        <i className="bi bi-whatsapp fs-5 mx-1"></i> en la esquina inferior
        derecha, o escribirnos a{" "}
        <a href="mailto:contacto@avisale.uy" className="text-primary">
          contacto@avisale.uy
        </a>
        .
      </p>
      <hr />
      <Link
        to="/clientes/DASHBOARD"
        className="text-primary d-block text-center"
      >
        VOLVER
      </Link>
      <FaqExampleModal
        modalData={modalData}
        modalShow={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}

export default Faq;

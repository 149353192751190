import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";

function CustomTooltip({ showTip, tollTipText, target, placement }) {
  return (
    <Overlay
      target={target.current}
      show={showTip}
      placement={placement ? placement : "left"}
    >
      {(props) => (
        <Tooltip id="overlay-example" {...props}>
          {tollTipText}
        </Tooltip>
      )}
    </Overlay>
  );
}

export default CustomTooltip;

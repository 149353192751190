import { Box, TextField } from "@mui/material";
import { useState, useRef } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import styles from "../customer/Customer.module.css";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";

function Contact() {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const captchaRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    if (!token)
      return toast.error(
        "FALLO VERIFICACIÓN DEL CAPTCHA, DEBE COMPLETARLO PARA CONTINUAR."
      );
    const requiredValues = [phone, email, subject, content];
    if (requiredValues.includes(""))
      return toast.error("TODOS LOS CAMPOS SON OBLIGATORIOS, FAVOR VERIFICAR.");
    setIsLoading(true);
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/clientes/contact`,
      data: { phone, email, content, token, subject },
    });
    if (response) {
      setPhone("");
      setEmail("");
      setContent("");
      setSubject("");
      setIsLoading(false);
      toast.success(
        "GRACIAS POR CONTACTARNOS, EL MENSAJE FUE ENVIADO, A LA BREVEDAD NOS COMUNICAREMOS CONTIGO."
      );
      navigate("/avisar");
    }
  };

  return (
    <div className="contactPage rounded px-4 w-100">
      <h1 className="text-center fs-2 pt-3">CONTACTO</h1>
      <hr />
      <h2 className="text-center fs-4 mb-0">FORMULARIO DE CONTACTO</h2>
      <form action="" onSubmit={handleSubmit} className="py-4 mb-4">
        <div className="mb-4">
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <TextField
              id="phone"
              autoComplete="off"
              label={
                <span>
                  <i className="bi bi-phone-fill me-2 fs-4"></i>TELÉFONO
                </span>
              }
              variant="standard"
              type="number"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                style: { fontSize: "1.3rem", fontWeight: "500" },
              }}
              value={phone}
              onChange={(e) => setPhone(e.target.value.trim())}
              className={styles.customerLoginInput}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <TextField
              id="email"
              autoComplete="off"
              label={
                <span>
                  <i className="bi bi-envelope-at fs-4 me-2"></i>E-MAIL
                </span>
              }
              variant="standard"
              type="email"
              inputProps={{
                style: { fontSize: "1.3rem", fontWeight: "500" },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
              className={styles.customerLoginInput}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <TextField
              id="subject"
              label="ASUNTO"
              variant="standard"
              inputProps={{
                style: { fontSize: "1.3rem", fontWeight: "500" },
              }}
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className={styles.customerLoginInput}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <TextField
              id="content"
              multiline
              rows={7}
              label="MENSAJE"
              variant="standard"
              inputProps={{
                style: { fontSize: "1.3rem", fontWeight: "500" },
              }}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className={styles.customerLoginInput}
            />
          </Box>
          <div className="d-flex justify-content-center">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              ref={captchaRef}
              lang="es"
            />
          </div>
        </div>
        <div className="text-center">
          {isLoading ? (
            <button
              id="login-btn"
              type="button"
              className="py-2 px3 mt-3 btn btn-dark shadow-lg fs-5 w-100 d-flex justify-content-center"
            >
              <Spinner animation="grow" variant="light" className="me-3" />
              ENVIANDO...
            </button>
          ) : (
            <button
              id="login-btn"
              type="submit"
              className="py-2 px3 mt-3 btn btn-dark shadow-lg fs-5 w-100 d-flex justify-content-center"
            >
              ENVIAR
            </button>
          )}
        </div>
      </form>

      <hr />
      <h2 className="text-center fs-4 mb-3">UBICACIÓN</h2>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3271.914437376223!2d-56.16629742367844!3d-34.90859667366448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f81a66fdd0871%3A0xbca8737182b8046b!2sBv.%20Gral.%20Artigas%201117%2C%2011200%20Montevideo%2C%20Departamento%20de%20Montevideo!5e0!3m2!1ses-419!2suy!4v1681710147065!5m2!1ses-419!2suy"
        style={{ border: 0, width: "100%", height: "40vh" }}
        allowFullScreen={true}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="map"
      ></iframe>
      <hr />
      <h2 className="text-center fs-4 mb-3">INFORMACIÓN</h2>
      <h3 className="fs-6">AVISALE.UY</h3>
      <ul>
        <li>
          <i className="bi bi-geo-alt"></i> BVAR ARTIGAS 1117 / 703
        </li>
        <li>
          <i className="bi bi-envelope-at">
            {" "}
            <a
              href="mailto:contacto@avisale.uy"
              className="text-primary text-dark"
            >
              contacto@avisale.uy
            </a>
          </i>
        </li>
        <li>
          <i className="bi bi-phone-fill"></i>{" "}
          <a href="tel:+59898993017">(598) 098 993 017</a>
        </li>
      </ul>
      <hr />
      <Link
        to="/clientes/dashboard"
        className="d-block text-center text-primary fs-4 mb-4"
      >
        Volver
      </Link>
    </div>
  );
}

export default Contact;

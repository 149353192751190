import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";

function DeleteModal({
  itemToDelete,
  modalShow,
  onHide,
  customer,
  setCustomer,
}) {
  const user = useSelector((state) => state.user);
  const handleDelete = async () => {
    onHide();
    const entity = itemToDelete.plate ? "vehiculos" : "propiedades";
    const response = await axios({
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      url: `${process.env.REACT_APP_API_URL}/api/${entity}/${itemToDelete.id}`,
    });
    if (response.data.msg) return toast.error(response.data.msg);
    setCustomer(
      itemToDelete.plate
        ? {
            ...customer,
            vehicles: customer.vehicles.filter(
              (vehicle) => vehicle.id !== itemToDelete.id
            ),
          }
        : {
            ...customer,
            estates: customer.estates.filter(
              (estate) => estate.id !== itemToDelete.id
            ),
          }
    );
    return toast.success(
      itemToDelete.plate
        ? "SE HA ELIMINADO VEHÍCULO PERMANENTEMENTE."
        : "SE HA ELIMINADO PROPIEDAD PERMANENTEMENTE."
    );
  };

  return (
    itemToDelete && (
      <Modal
        show={modalShow}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            CONFIRMACIÓN
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {itemToDelete.plate ? (
            <p>
              SE ELIMINARÁ EL VEHÍCULO: "
              <strong>
                {itemToDelete.alias
                  ? itemToDelete.alias.toUpperCase()
                  : "MATRÍCULA"}{" "}
                |{" "}
                {itemToDelete.plate
                  ? itemToDelete.plate.toUpperCase()
                  : itemToDelete.code.toUpperCase()}
              </strong>
              " .
            </p>
          ) : (
            <p>
              SE ELIMINARÁ LA PROPIEDAD: "
              <strong>
                {itemToDelete.alias
                  ? itemToDelete.alias.toUpperCase()
                  : "CÓDIGO"}{" "}
                |{" "}
                {itemToDelete.plate
                  ? itemToDelete.plate.toUpperCase()
                  : itemToDelete.code.toUpperCase()}
              </strong>
              " .
            </p>
          )}
          <p>¿DESEA CONTINUAR?</p>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={onHide} className="btn btn-dark">
            CANCELAR
          </button>
          <button
            type="button"
            onClick={handleDelete}
            className="btn btn-success"
          >
            CONTINUAR
          </button>
        </Modal.Footer>
      </Modal>
    )
  );
}

export default DeleteModal;

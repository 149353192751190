import axios from "axios";
import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";

function Estates() {
  const [estates, setEstates] = useState([]);

  useEffect(() => {
    const getEstates = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/propiedades`
      );
      setEstates(response.data);
    };
    getEstates();
  }, []);

  return estates.length ? (
    <div>
      <h1 className="text-center my-3">Propiedades</h1>
      <Table responsive striped bordered hover className="p-0 m-0">
        <thead className="bg-dark text-light">
          <tr className="text-center">
            <th className="idTableField">Id</th>
            <th>Código</th>
            <th>Alias</th>
            <th>Nº Alternativo</th>
            <th>Registrado</th>
            <th>Modificado</th>
            <th>Cliente</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {estates.map((estate) => (
            <tr key={estate.id}>
              <td className="idTableField">{estate.id}</td>
              <td>{estate.code}</td>
              <td>{estate.alias}</td>
              <td>{estate.alternative_number}</td>
              <td>{new Date(estate.createdAt).toLocaleDateString("es-ES")}</td>
              <td>{new Date(estate.updatedAt).toLocaleDateString("es-ES")}</td>
              <td>{estate.customerId}</td>
              <td>
                <div className="d-flex justify-content-evenly">
                  <i className="bi bi-trash3 text-secondary fs-5"></i>
                  <i className="bi bi-pencil-square text-danger fs-5"></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  ) : (
    <p className="text-danger">No hay propiedades para mostrar.</p>
  );
}

export default Estates;

import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";

function AdminMenu({ show, handleClose }) {
  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="mx-auto">Menú</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="offCanvasMenuList">
            <li onClick={handleClose}>
              <Link to="/admin/dashboard">
                <i className="bi bi-graph-up-arrow fs-5 me-2"></i>Dashboard
              </Link>
            </li>
            <hr />
            <li onClick={handleClose}>
              <Link to="/admin/usuarios">
                <i className="bi bi-person-vcard fs-5 me-2"></i>Usuarios
              </Link>
            </li>
            <hr />
            <li onClick={handleClose}>
              <Link to="/admin/clientes">
                <i className="bi bi-people fs-5 me-2"></i>Clientes
              </Link>
            </li>
            <hr />
            <li onClick={handleClose}>
              <Link to="/admin/activaciones">
                <i className="bi bi-key fs-5 me-2"></i>Códigos de Activación
              </Link>
            </li>
            <hr />
            <li onClick={handleClose}>
              <Link to="/admin/vehiculos">
                <i className="bi bi-car-front fs-5 me-2"></i>Vehículos
              </Link>
            </li>
            <hr />
            <li onClick={handleClose}>
              <Link to="/admin/propiedades">
                <i className="bi bi-buildings fs-5 me-2"></i>Propiedades
              </Link>
            </li>
            <hr />
            <li onClick={handleClose}>
              <Link to="/admin/predefinidos_vehiculos">
                <i className="bi bi-chat-dots fs-5 me-2"></i>Predefinidos
                Vehículos
              </Link>
            </li>
            <hr />
            <li onClick={handleClose}>
              <Link to="/admin/predefinidos_propiedades">
                <i className="bi bi-chat-left-dots fs-5 me-2"></i>Predefinidos
                Propiedades
              </Link>
            </li>
            <hr />
            <li onClick={handleClose}>
              <Link to="/admin/registros">
                <i className="bi bi-clock-history fs-5 me-2"></i>Logs Mensajes
              </Link>
            </li>
            <hr />
            <li onClick={handleClose}>
              <Link to="/admin/logout">
                <i className="bi bi-box-arrow-left fs-5 me-2"></i>Salir
              </Link>
            </li>
            <hr />
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default AdminMenu;

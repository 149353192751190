import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
function RegisterConfirmationModal({
  modalConfirmShow,
  onHide,
  phone,
  email,
  name,
  password,
  reachedBy,
  handleSubmit,
}) {
  const [hidePassword, setHidePassword] = useState(false);
  return (
    <Modal
      show={modalConfirmShow}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          CONFIRMACIÓN DE DATOS
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflw-auto">
        <p>
          VERIFIQUE LOS DATOS INGRESADOS. PRESIONE MODIFICAR SI DEBE CORREGIR
          ALGUNO O CONFIRMAR SI TODO ES CORRECTO.
        </p>
        SE REGISTRARÁ CON LOS SIGUIENTES DATOS:
        <ul style={{ listStyle: "none", paddingLeft: 0 }}>
          <li>
            TELÉFONO: <span className="fw-semibold">{phone}</span>
          </li>
          <li>
            EMAIL:{" "}
            <div
              id="emailInRegister"
              className="overflow-x-scroll  fw-semibold"
            >
              {email}
            </div>
          </li>
          <li>
            NOMBRE/APODO: <span className="fw-semibold">{name}</span>
          </li>
          <li>
            CONTRASEÑA:{" "}
            {password && !hidePassword && (
              <>
                <span className="fw-semibold">
                  {password.replace(/./g, "*")}
                </span>
                <VisibilityOffIcon
                  sx={{
                    color: "action.active",
                    mr: 1,
                    my: 0.5,
                    ml: 2,
                    fontSize: 28,
                  }}
                  onClick={() => setHidePassword(true)}
                />
              </>
            )}
            {password && hidePassword && (
              <>
                <span className="fw-semibold">{password}</span>
                <VisibilityIcon
                  sx={{
                    color: "action.active",
                    mr: 1,
                    my: 0.5,
                    ml: 2,
                    verticalAlign: "middle",
                    fontSize: 28,
                  }}
                  onClick={() => setHidePassword(false)}
                />
              </>
            )}
          </li>
          <li>
            ¿CÓMO CONOCIÓ AVISALE.UY?{" "}
            <span className="fw-semibold">{reachedBy}</span>
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={onHide} className="btn btn-secondary">
          MODIFICAR
        </button>
        <button type="button" onClick={handleSubmit} className="btn btn-dark">
          CONFIRMAR
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default RegisterConfirmationModal;

import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import PresetsEstateModal from "./PresetsEstateModal";

import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import CustomTooltip from "../CustomTooltip";

function NewEstate() {
  const [code, setCode] = useState("");
  const [alias, setAlias] = useState("");
  const [alternative_number, setAlternative_number] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [validatingLoader, setValidatingLoader] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [showTip, setShowTip] = useState(false);
  const [tollTipText, setTollTipText] = useState("");
  const [helpTipPlacement, setHelpTipPlacement] = useState("");
  const target = useRef(null);

  const handleTip = (text, selected, placement) => {
    target.current = selected.target;
    setTollTipText(text);
    setHelpTipPlacement(placement);
    setShowTip(!showTip);
  };

  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    const getNewCode = async () => {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/api/propiedades/codigo`,
        method: "get",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      console.log(response);
      setCode(response.data);
      setSubmitDisabled(false);
    };
    getNewCode();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredValues = [code];
    if (requiredValues.includes(""))
      return toast.error("DEBE INGRESAR CÓDIGO.");
    setValidatingLoader(true);
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/propiedades`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      data: { code, alias, alternative_number },
    });
    if (!response.data.msg) {
      setValidatingLoader(false);
      toast.success("PROPIEDAD REGISTRADA");
      navigate("/clientes/dashboard");
    } else {
      setValidatingLoader(false);
      toast.error(response.data.msg);
    }
  };

  return (
    <div className="d-flex flex-column">
      <h1 className="fs-1 text-center">NUEVA PROPIEDAD</h1>
      <div className="customerVehicle w-100">
        <form action="" onSubmit={handleSubmit}>
          <TextField
            label={
              <span>
                <i className="bi bi-buildings fs-4 me-2"></i>CÓDIGO AUTOGENERADO
              </span>
            }
            value={code}
            variant="standard"
            fullWidth
            inputProps={{
              readOnly: true,
              style: { fontSize: 20, letterSpacing: "3px" },
            }}
            className="my-3"
            onClick={() =>
              toast.info("EL CÓDIGO ES AUTOGENERADO Y NO SE PUEDE MODIFICAR.")
            }
            onChange={(e) => {
              setCode(e.target.value.trim().toUpperCase());
              setSubmitDisabled(false);
            }}
          />
          <div className="position-relative">
            <TextField
              label={
                <span>
                  <i className="bi bi-diamond-half fs-4 me-2"></i>ALIAS
                </span>
              }
              value={alias}
              variant="standard"
              fullWidth
              inputProps={{
                style: { fontSize: 20, letterSpacing: "3px" },
                maxLength: 12,
              }}
              className="mb-3"
              onChange={(e) => {
                e.target.value.length <= 12 &&
                  setAlias(e.target.value.trim().toUpperCase());
                setSubmitDisabled(false);
              }}
            />
            <i
              className="bi bi-question-circle ms-2 position-absolute helpTip"
              ref={target}
              onClick={(e) =>
                handleTip(
                  'INGRESE UN ALIAS PARA LA PROPIEDAD SI LO DESEA EJ: "CASA CENTRO". (12 CARACTERES MÁXIMO. ES DE USO INTERNO, NO SERÁ VISTO POR USUARIOS QUE AVISEN)',
                  e
                )
              }
            ></i>
          </div>
          <div className="position-relative mb-4">
            <TextField
              label={
                <span>
                  <i className="bi bi-phone-fill fs-4 me-2"></i>CELULAR
                  ALTERNATIVO
                </span>
              }
              id="input-with-sx"
              variant="standard"
              type="number"
              inputProps={{
                style: { fontSize: 20, letterSpacing: "3px" },
              }}
              className="mb-3"
              value={alternative_number}
              fullWidth
              onChange={(e) => {
                e.target.value.length <= 15 &&
                  setAlternative_number(e.target.value.trim());
                setSubmitDisabled(false);
              }}
            />
            <i
              className="bi bi-question-circle ms-2 position-absolute helpTip"
              ref={target}
              onClick={(e) =>
                handleTip(
                  "INGRESE NÚMERO DE CELULAR ALTERNATIVO SI LO DESEA. ESTE RECIBIRÁ LAS MISMAS NOTIFICACIONES QUE USTED. (ES DE USO INTERNO, NO SERÁ VISTO POR USUARIOS QUE AVISEN)",
                  e
                )
              }
            ></i>
          </div>
          <p>
            AL INGRESAR UNA PROPIEDAD, TODOS LOS MENSAJES PREDEFINIDOS EN
            NUESTRA BASE DE DATOS QUEDARÁN HABILITADOS POR DEFECTO PARA LA
            MISMA.
            <span
              className="ms-2 text-primary"
              onClick={() => setModalShow(true)}
            >
              VER LISTA DE MENSAJES
            </span>
          </p>
          <p>
            PODRÁ PERSONALIZAR DATOS Y HABILITAR O DESHABILITAR MENSAJES EN
            DASHBOARD / PROPIEDADES / (PROPIEDAD QUE DESEE MODIFICAR).
          </p>
          <p>
            UNA VEZ CONFIRMADO EL REGISTRO, COMUNIQUESE PARA CONOCER LAS
            DIFERENTES PLACAS DIPSONIBLES Y COORDINAR ENTREGA.
          </p>
          {validatingLoader ? (
            <button
              id="login-btn"
              type="button"
              className="btn btn-dark rounded-pill w-100 mt-3  shadow confirmButton"
            >
              <span className="d-flex align-items-center justify-content-center">
                <Spinner animation="grow" variant="light" className="me-3" />
                GUARDANDO...
              </span>
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-dark rounded-pill w-100 mt-3 shadow confirmButton"
              disabled={submitDisabled}
            >
              CONFIRMAR REGISTRO
            </button>
          )}
        </form>
        <hr />
        <div className="text-center mb-4">
          <Link className="text-primary fw-semibold" to="/clientes/dashboard">
            VOLVER
          </Link>
        </div>
      </div>
      <CustomTooltip
        showTip={showTip}
        tollTipText={tollTipText}
        target={target}
        placement={helpTipPlacement}
      />
      <PresetsEstateModal
        modalShow={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}

export default NewEstate;

import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

function NewCodeModal({ modalShow, onHide }) {
  const [newCode, setNewCode] = useState("");
  const [serviceType, setServiceType] = useState("sms");
  const user = useSelector((state) => state.user);
  const handleCreate = async () => {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/activaciones`,
      method: "post",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      data: { type: serviceType },
    });
    console.log(response.data);
    setNewCode(response.data.code);
  };

  return (
    <Modal
      show={modalShow}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          GENERACIÓN DE CÓDIGO
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {newCode ? (
          <p>
            SE HA GENERADO EL CÓDIGO <strong>{newCode}</strong>
          </p>
        ) : (
          <>
            <select
              name="type"
              id="type"
              value={serviceType}
              onChange={(e) => setServiceType(e.target.value)}
            >
              <option value="sms">Servicio SMS</option>
              <option value="voice">Servicio de Llamada</option>
            </select>
            <p>Presione Generar para recibir nuevo código.</p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={onHide} className="btn btn-dark">
          CANCELAR
        </button>
        <button
          type="button"
          onClick={handleCreate}
          className="btn btn-success"
        >
          GENERAR
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewCodeModal;

import Modal from "react-bootstrap/Modal";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  dontShowAgainWelcomeModal,
  hideWelcomeModal,
} from "../../redux/pageSlice";
import { useState } from "react";
import sticker from "../../img/stickerAuto.png";
import plate from "../../img/placaPuerta.png";

function WelcomeModal() {
  const pageState = useSelector((state) => state.page);
  const [showCarSticker, setShowCarSticker] = useState(false);
  const [showDoorPlate, setShowDoorPlate] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const dispatch = useDispatch();
  console.log(pageState);
  const onHide = () => {
    dispatch(hideWelcomeModal());
  };

  const showImageModal = () => {
    setShowImage(true);
    setShowCarSticker(true);
  };

  const showPlateModal = () => {
    setShowImage(true);
    setShowDoorPlate(true);
  };

  const closeImageModal = () => {
    setShowImage(false);
    setShowCarSticker(false);
    setShowDoorPlate(false);
  };

  return (
    <Modal
      show={pageState.firstTimeInPage}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h1 className="m-0">BIENVENIDO/A</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3 className="text-center">SOBRE NOSOTROS</h3>
        <p>
          <strong>avisale.uy</strong> es la primer y única plataforma que
          permite notificar de forma gratuita y sencilla al responsable de un
          vehículo o propiedad que requiere atención inmediata.
        </p>
        <p>
          Si algo sucede con tu vehículo o propiedad, ahora cualquier persona
          que lo note podrá avisarte sin necesidad de exponer tus datos
          personales e incluso si no conoce nuestro servicio.
        </p>
        <h3 className="mt-4 mb-3 text-center">¿CÓMO FUNCIONA?</h3>
        <h4>USUARIO QUE REGISTRA</h4>
        <p>
          Nuestra plataforma te permitirá registrar tus vehículos y propiedades
          junto con una lista predefinida (modificable) de posibles motivos de
          aviso, ej: "vidrios rotos", "mal estacionado", "luces encendidas",
          "incendio", entre otras.
        </p>
        <p>
          Al contratar el servicio obtendrás sin costo 1 sticker para pegar en
          tu vehículo y una plaqueta para la puerta de tu hogar, estos elementos
          permitirán a cualquier persona avisarte aún si esta no conocía el
          servicio. Aquí puedes ver un ejemplo de{" "}
          <span className="text-primary" onClick={showImageModal}>
            sticker
          </span>{" "}
          y uno de{" "}
          <span className="text-primary" onClick={showPlateModal}>
            plaqueta
          </span>
          . (Podrás comprar adicionales si lo deseas)
        </p>
        <h4 className="mt-4">USUARIOS QUE AVISAN</h4>
        <p>
          Para avisarte, cualquier persona que note algo, solo deberá ingresar
          en avisale.uy, ingresar tu código de propiedad o matrícula del
          vehículo, seleccionar el mensaje que desea enviarte y confirmar el
          envío. Este procedimiento es totalmente gratuito para quien avisa y no
          genera ningún costo adicional para ti.
        </p>
        <p>
          <strong>¡Eso es todo!</strong> Recibirás un SMS indicando la matrícula
          o código y el motivo del aviso que hayan seleccionado.
        </p>
        <p>
          Recordá que el sticker o plaqueta tienen la información necesaria para
          poder avisarte, pero tus datos personales jamás serán expuestos, ya
          que las personas que avisan solo necesitan la matrícula del vehículo o
          el código de la propiedad para hacerlo.
        </p>
        <h3 className="fs-6 mt-4">¿TENÉS ALGUNA DUDA?</h3>
        <p>
          Te invitamos a visitar nuestro apartado de{" "}
          <a href="/preguntas_frecuentes" className="text-primary">
            Preguntas frecuentes
          </a>
          . (se cerrará pantalla de bienvenida)
        </p>
        <p>
          Para contactarnos podés ir al apartado de{" "}
          <a href="/preguntas_frecuentes" className="text-primary">
            Contacto
          </a>
          . (se cerrará pantalla de bienvenida)
        </p>
        <p>O si lo deseas podés hacerlo por los siguientes medios:</p>
        <ul>
          <li>
            <i className="bi bi-whatsapp me-2"></i>
            <a
              href="https://api.whatsapp.com/send/?phone=59898993017"
              target="_blank"
              rel="noreferrer"
            >
              (598) 098 993 017
            </a>
          </li>
          <li>
            <i className="bi bi-envelope-at">
              <a
                href="mailto:contacto@avisale.uy"
                className="text-primary text-dark ms-2"
              >
                contacto@avisale.uy
              </a>
            </i>
          </li>
        </ul>
        <div className="d-flex align-items-center justify-content-end mt-4">
          No volver a mostrar
          <i
            className={
              !pageState.hideWelcomeModal
                ? "bi bi-square ms-2"
                : "bi bi-check-square ms-2"
            }
            onClick={() => dispatch(dontShowAgainWelcomeModal())}
          ></i>
        </div>
        {showImage && (
          <Modal
            show={true}
            onHide={closeImageModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            fullscreen={true}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {showCarSticker && (
                <div>
                  <img className="img-fluid" src={sticker} alt="Sticker Auto" />
                  <hr />
                  <h4>Sticker para vehículo</h4>
                  <ul>
                    <li>Material: Vinilo (No Papel).</li>
                    <li>Tinta: Solvente, resiste intemperie.</li>
                    <li>Medidas: 13 cm x 6.5 cm (L x A).</li>
                    <li>Fijación: Adhesivo.</li>
                  </ul>
                </div>
              )}
              {showDoorPlate && (
                <div>
                  <img className="img-fluid" src={plate} alt="Sticker Auto" />
                  <hr />
                  <h4>Plaqueta para puerta</h4>
                  <ul>
                    <li>Material: PVC (2mm).</li>
                    <li>Lámina: Vinilo.</li>
                    <li>Tinta: Solvente, resiste intemperie.</li>
                    <li>Medidas: 6 cm x 4 cm x 2mm (L x A x P).</li>
                    <li>Fijación: Cinta doble faz (incluída).</li>
                  </ul>
                </div>
              )}
              <hr />
              <div
                className="text-center fw-bold text-primary"
                onClick={closeImageModal}
              >
                VOLVER
              </div>
            </Modal.Body>
          </Modal>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={onHide} className="btn btn-dark">
          CERRAR
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default WelcomeModal;

import "./AdminPanel.css";
import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import AdminMenu from "../admin/AdminMenu";
import AdminNavbar from "../admin/AdminNavbar";

function AdminPanel() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const toggleShowOffcanvas = () => setShow((prev) => !prev);

  useEffect(() => {
    navigate("/admin/dashboard");
    // eslint-disable-next-line
  }, []);

  return (
    <div className="adminPanel">
      <AdminNavbar toggleShowOffcanvas={toggleShowOffcanvas} />
      <AdminMenu show={show} handleClose={handleClose} />
      <Container>
        <Outlet />
      </Container>
    </div>
  );
}

export default AdminPanel;

import Modal from "react-bootstrap/Modal";

function TermsInRegisterModal({ modalShow, handleConfirm, handleDimiss }) {
  return (
    <Modal
      show={modalShow}
      onHide={handleDimiss}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          TÉRMINOS Y CONDICIONES
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3 className="fs-5">Servicio</h3>
        <p>
          1.1. La aplicación avisale.uy es proporcionada por Avisale Uruguay,
          una empresa y marca registrada en Uruguay.
        </p>
        <p>
          1.2. La aplicación avisale.uy permite a los usuarios enviar
          notificaciones sin costo a los propietarios de vehículos o propiedades
          registrados en la aplicación.
        </p>
        <p>
          1.3. Para habilitar el servicio y registrar sus vehículos o
          propiedades, los usuarios deberán abonar una única cuota anual de
          1000(mil) pesos uruguayos, siendo posible acceder a descuentos y
          promociones que reduzcan el antes mencionado valor.
        </p>
        <p>
          1.4. La empresa se reserva el derecho de modificar, suspender o
          interrumpir el servicio de la aplicación avisale.uy en cualquier
          momento sin previo aviso.
        </p>
        <h3 className="fs-5">Privacidad de los datos</h3>
        <p>
          2.1. Nos comprometemos a proteger tu información personal. La
          información que recopilamos se utiliza para mejorar tu experiencia en
          nuestro sitio web. No compartimos, vendemos ni alquilamos tu
          información personal con terceros, a menos que estemos obligados por
          ley a hacerlo.
        </p>
        <p>
          2.2. Cumplimos con la Ley de Protección de Datos Personales y Acción
          de Habeas Data (Ley N° 18.331) y su decreto reglamentario (Decreto N°
          414/009) en Uruguay, la cual establece los principios y procedimientos
          para el tratamiento de datos personales, incluyendo la recopilación,
          almacenamiento, uso, transferencia y eliminación de dichos datos.
        </p>
        <h3 className="fs-5">Responsabilidad</h3>
        <p>
          3.1. La empresa no se hace responsable en caso de que la operadora
          telefónica presente algún inconveniente que pudiera imposibilitar el
          envío de la notificación al propietario.
        </p>
        <p>
          3.2. Si un usuario indica que el servicio no funciona, se procederá a
          realizar las pruebas pertinentes en tiempo real para verificar el
          correcto funcionamiento del mismo. Si se constata que el servicio no
          funciona, se ejecutarán las acciones necesarias para lograr el
          correcto funcionamiento del servicio. Si correspondiera, se podría
          otorgar alguna bonificación al cliente.
        </p>
        <p>
          3.3. La empresa no es responsable de cualquier actividad ilícita
          realizada a través de la aplicación avisale.uy y no se hace
          responsable de las acciones de los usuarios.
        </p>
        <p>
          3.4. Si se presenta una denuncia contra un usuario de la aplicación
          avisale.uy, la empresa iniciará inmediatamente la investigación
          correspondiente y pausará el servicio hasta que se resuelva la
          denuncia.
        </p>
        <p>
          3.5. Si se confirma que un usuario ha realizado una actividad ilícita
          a través de la aplicación avisale.uy, la empresa dará de baja
          automáticamente el servicio para el usuario denunciado sin derecho a
          reclamo ni devolución de dinero.
        </p>
        <h3 className="fs-5">Propiedad intelectual</h3>
        <p>
          4.1. Todos los derechos de propiedad intelectual de la aplicación
          avisale.uy son propiedad exclusiva de Luis Romero con cédula
          3.3624.570-5, registro nº FZ334.
        </p>
        <p>
          4.2. Los usuarios de la aplicación avisale.uy no pueden utilizar,
          copiar o distribuir ninguna parte de la aplicación sin el
          consentimiento expreso de la empresa.
        </p>
        <h3 className="fs-5">Cambios en los términos y condiciones</h3>
        <p>
          5.1. La empresa se reserva el derecho de modificar los términos y
          condiciones de la aplicación avisale.uy en cualquier momento sin
          previo aviso.
        </p>
        <p>
          5.2. Si los usuarios continúan utilizando la aplicación avisale.uy
          después de que se hayan realizado cambios en los términos y
          condiciones, se considerará que han aceptado los nuevos términos y
          condiciones.
        </p>
        <p>
          Al utilizar la aplicación avisale.uy, los usuarios aceptan los
          términos y condiciones establecidos en este documento. Cualquier
          incumplimiento de estos términos y condiciones puede resultar en la
          terminación inmediata del acceso a la aplicación avisale.uy.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={handleDimiss}
          className="btn btn-secondary"
        >
          CANCELAR
        </button>
        <button type="button" onClick={handleConfirm} className="btn btn-dark">
          ACEPTAR
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default TermsInRegisterModal;

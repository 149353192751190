import Navbar from "react-bootstrap/Navbar";
import avisaleLogo from "../../img/avisaleLogo.png";
import Container from "react-bootstrap/Container";

function AdminNavbar({ toggleShowOffcanvas }) {
  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <img
            src={avisaleLogo}
            alt="Logo de avisale.com.uy"
            className="logoNavbar"
          />
          <i
            className="bi bi-list text-light offCanvasButton"
            onClick={toggleShowOffcanvas}
          ></i>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import PublicNavbar from "../public/PublicNavbar";
import PublicMenu from "../public/PublicMenu";

import { useSelector } from "react-redux";
import avisaleLogo from "../../img/avisaleLogo.png";

import styles from "../public/Public.module.css";
import { Outlet } from "react-router-dom";
import PublicLargeMenu from "../public/PublicLargeMenu";
import WelcomeModal from "../customer/WelcomeModal";

function IndexPanel() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const toggleShowOffcanvas = () => setShow((prev) => !prev);
  const navigate = useNavigate();

  const location = useLocation();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (location.state) return navigate(location.state);
    if (location.pathname !== "/")
      return navigate(location.pathname + location.search);
    return navigate("/avisar");
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`row g-0 ${styles.homeRow}`}>
      <div
        className={`col-md-4 col-lg-7 d-none d-md-flex justify-content-center align-items-center ${styles.homeLeft}`}
      >
        <Link to="/avisar">
          <img src={avisaleLogo} id="brandLogo" className="w-100 px-5" alt="" />
        </Link>
      </div>
      <div className={`col-md-8 col-lg-5 ${styles.homeRigth}`} id="homeRight">
        <PublicLargeMenu
          toggleShowOffcanvas={toggleShowOffcanvas}
          show={show}
        />
        <PublicNavbar toggleShowOffcanvas={toggleShowOffcanvas} />
        <PublicMenu handleClose={handleClose} show={show} user={user} />
        <div
          className={
            location.pathname !== "/sobre_nosotros" &&
            location.pathname !== "/legal"
              ? "d-flex p-4 justify-content-center w-100"
              : "d-flex justify-content-center pb-4 w-100"
          }
        >
          <Outlet />
        </div>
      </div>
      <WelcomeModal />
    </div>
  );
}

export default IndexPanel;

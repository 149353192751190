import Navbar from "react-bootstrap/Navbar";
import avisaleLogo from "../../img/avisaleLogo.png";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

function CustomerNavbar({ toggleShowOffcanvas }) {
  return (
    <>
      <Navbar
        bg="dark"
        variant="dark"
        expand="lg"
        className="fixed-top d-block d-md-none border-bottom"
      >
        <Container>
          <Link to="/avisar">
            <img
              src={avisaleLogo}
              alt="Logo de avisale.com.uy"
              className="logoNavbar"
            />
          </Link>
          <i
            className="bi bi-list text-light offCanvasButton"
            onClick={toggleShowOffcanvas}
          ></i>
        </Container>
      </Navbar>
    </>
  );
}

export default CustomerNavbar;

import loader from "../img/cargador.gif";

function Loader() {
  return (
    <div className="mt-5">
      {/* Abre Cargador */}
      <div className="w-100 text-center">
        <img className="l" src={loader} width="35%" alt="Cargador.." />
        <div className="loader pt-3">
          <span className="l">C</span>
          <span className="o">A</span>
          <span className="a">R</span>
          <span className="d">G</span>
          <span className="i">A</span>
          <span className="n">N</span>
          <span className="g">D</span>
          <span className="d1">O</span>
        </div>
      </div>
      {/* Cierra cargador */}
    </div>
  );
}

export default Loader;

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Routes, Route, useLocation } from "react-router-dom";

import Index from "./components/pages/IndexPanel";

import NotFound from "./components/pages/NotFound";

import AdminPrivateRoutes from "./components/AdminPrivateRoutes";
import SellerPrivateRoutes from "./components/SellerPrivateRoutes";
import CustomerPrivateRoutes from "./components/CustomerPrivateRoutes";

import Faq from "./components/pages/Faq";
import AboutThisProject from "./components/pages/AboutThisProject";
import TermsAndConditions from "./components/pages/TermsAndConditions";
import Products from "./components/public/Products";

import IndexStepOne from "./components/public/IndexStepOne";
import IndexStepTwo from "./components/public/IndexStepTwo";

import AdminPanel from "./components/pages/AdminPanel";
import Users from "./components/admin/Users";
import Vehicles from "./components/admin/Vehicles";
import VehiclePresets from "./components/admin/VehiclePresets";
import EstatePresets from "./components/admin/EstatePresets";
import Estates from "./components/admin/Estates";
import Activations from "./components/admin/Activations";
import Logs from "./components/admin/Logs";
import AdminDashboard from "./components/admin/Dashboard";
import Customers from "./components/admin/Customers";
import AdminLogin from "./components/admin/Login";
import AdminLogout from "./components/admin/Logout";

import SellerLogin from "./components/seller/Login";
import SellerLogout from "./components/seller/Logout";
import SellerPanel from "./components/pages/SellerPanel";
import SellerDashboard from "./components/seller/Dashboard";
import SellerActivations from "./components/seller/Activations";
import SellerProfile from "./components/seller/Profile";

import CustomerLogin from "./components/customer/Login";
import CustomerRegister from "./components/customer/Register";
import CustomerDashboard from "./components/customer/Dashboard";
import CustomerVehicles from "./components/customer/Vehicles";
import CustomerOneVehicle from "./components/customer/OneVehicle";
import CustomerNewVehicle from "./components/customer/NewVehicle";
import CustomerEstates from "./components/customer/Estates";
import CustomerOneEstate from "./components/customer/OneEstate";
import CustomerNewEstate from "./components/customer/NewEstate";
import CustomerProfile from "./components/customer/Profile";
import CustomerLogs from "./components/customer/Logs";
import CustomerPanel from "./components/pages/CustomerPanel";
import CustomerLogout from "./components/customer/Logout";
import CustomerPasswordReset from "./components/customer/PasswordReset";
import { useEffect, useState } from "react";
import ResetLS from "./components/ResetLS";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Checkout from "./components/pages/Checkout";
import ThanksPage from "./components/pages/ThanksPage";
import Contact from "./components/pages/Contact";
import { Analytics } from "@vercel/analytics/react";
import { useDispatch, useSelector } from "react-redux";
import { showWelcomeModalNextTime } from "./redux/pageSlice";
function App() {
  const [whatsappShow, setWhatsappShow] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pageState = useSelector((state) => state.page);
  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) navigate("/clientes/logout");
        return error;
      }
    );

    const rightColumn = document.getElementById("homeRight");

    if (rightColumn) {
      rightColumn.scrollTo(0, 0);
      rightColumn.addEventListener("scroll", () => {
        if (rightColumn.scrollTop <= 70) setWhatsappShow((prev) => true);
        if (rightColumn.scrollTop > 70 && whatsappShow)
          return setWhatsappShow((prev) => false);
      });
    }

    return () => {
      if (rightColumn) {
        rightColumn.removeEventListener("scroll", () => {
          if (rightColumn.scrollTop <= 70) setWhatsappShow((prev) => true);
          if (rightColumn.scrollTop > 70 && whatsappShow)
            return setWhatsappShow((prev) => false);
        });
      }
    };
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("visibilitychange", handleShowWelcomeModal);
    return () => {
      window.removeEventListener("visibilitychange", handleShowWelcomeModal);
    };

    // eslint-disable-next-line
  }, []);
  const handleShowWelcomeModal = async () => {
    if (!pageState.hideWelcomeModal) {
      dispatch(showWelcomeModalNextTime());
    }
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/resetls" element={<ResetLS />} />
        <Route path="/" element={<Index />}>
          <Route path="avisar" element={<IndexStepOne />} />
          <Route path="vehiculos/:plate" element={<IndexStepTwo />} />
          <Route path="propiedades/:code" element={<IndexStepTwo />} />
          <Route path="sobre_nosotros" element={<AboutThisProject />} />
          <Route path="legal" element={<TermsAndConditions />} />
          <Route path="contacto" element={<Contact />} />
          <Route path="productos" element={<Products />} />
          <Route path="clientes/login" element={<CustomerLogin />} />
          <Route
            path="clientes/restablecer"
            element={<CustomerPasswordReset />}
          />
          <Route path="clientes/registro" element={<CustomerRegister />} />
          <Route path="preguntas_frecuentes" element={<Faq />} />
          <Route path="compra/:producto" element={<Checkout />} />
          <Route path="gracias" element={<ThanksPage />} />
        </Route>
        <Route path="/admin/logout" element={<AdminLogout />} />
        <Route path="/admin/login" element={<AdminLogin />} />

        <Route path="/ventas/login" element={<SellerLogin />} />
        <Route path="/ventas/logout" element={<SellerLogout />} />

        <Route path="/clientes/logout" element={<CustomerLogout />} />

        <Route element={<AdminPrivateRoutes />}>
          <Route path="/admin" element={<AdminPanel />}>
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="usuarios" element={<Users />} />
            <Route path="clientes" element={<Customers />} />
            <Route path="activaciones" element={<Activations />} />
            <Route path="vehiculos" element={<Vehicles />} />
            <Route path="propiedades" element={<Estates />} />
            <Route path="predefinidos_vehiculos" element={<VehiclePresets />} />
            <Route
              path="predefinidos_propiedades"
              element={<EstatePresets />}
            />
            <Route path="registros" element={<Logs />} />
          </Route>
        </Route>

        <Route element={<SellerPrivateRoutes />}>
          <Route path="/ventas" element={<SellerPanel />}>
            <Route path="dashboard" element={<SellerDashboard />} />
            <Route path="activaciones" element={<SellerActivations />} />
            <Route path="perfil" element={<SellerProfile />} />
          </Route>
        </Route>

        <Route element={<CustomerPrivateRoutes />}>
          <Route path="/clientes" element={<CustomerPanel />}>
            <Route path="dashboard" element={<CustomerDashboard />} />
            <Route path="vehiculos" element={<CustomerVehicles />} />
            <Route path="vehiculos/:id" element={<CustomerOneVehicle />} />
            <Route
              path="vehiculos/registrar"
              element={<CustomerNewVehicle />}
            />
            <Route path="propiedades" element={<CustomerEstates />} />
            <Route path="propiedades/:id" element={<CustomerOneEstate />} />
            <Route
              path="propiedades/registrar"
              element={<CustomerNewEstate />}
            />
            <Route path="historial" element={<CustomerLogs />} />
            <Route path="perfil" element={<CustomerProfile />} />
          </Route>
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
      <div id="whatsapp" className={!whatsappShow ? "hide" : ""}>
        <div className="d-flex align-items-center">
          <i
            onClick={() => setWhatsappShow((prev) => !prev)}
            className={
              whatsappShow
                ? "bi bi-arrow-right text-white close-whatsapp me-1 fs-5"
                : "bi bi-arrow-bar-left text-white close-whatsapp me-1 fs-2"
            }
          ></i>
          <a
            href="https://api.whatsapp.com/send/?phone=59898993017"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bi bi-whatsapp text-success ps-2"></i>
          </a>
        </div>
      </div>
      <ToastContainer />
      <Analytics />
    </div>
  );
}

export default App;

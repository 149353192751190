import Table from "react-bootstrap/Table";
import axios from "axios";
import { useState, useEffect } from "react";

function Customers() {
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const getCustomers = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/clientes`
      );
      setCustomers(response.data);
    };
    getCustomers();
  }, []);

  return customers.length > 0 ? (
    <div>
      <h1 className="text-center my-3">Clientes</h1>
      <Table responsive striped bordered hover className="p-0 m-0">
        <thead className="bg-dark text-light">
          <tr className="text-center">
            <th className="idTableField">Id</th>
            <th>Teléfono</th>
            <th>Correo</th>
            <th>Nombre</th>
            <th>Activo?</th>
            <th>Vehículos</th>
            <th>Propiedades</th>
            <th>Registrado</th>
            <th>Actualizado</th>
            <th>Id Activación</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {customers.map((customer) => (
            <tr key={customer.id}>
              <td className="idTableField">{customer.id}</td>
              <td>{customer.phone}</td>
              <td>{customer.email}</td>
              <td>{customer.name}</td>
              <td>{customer.isActive ? "SI" : "NO"}</td>
              <td>
                {customer.vehicles.length}/{customer.totalVehicles}
              </td>
              <td>
                {customer.estates.length}/{customer.totalEstates}
              </td>
              <td>
                {new Date(customer.createdAt).toLocaleDateString("es-ES")}
              </td>
              <td>
                {new Date(customer.updatedAt).toLocaleDateString("es-ES")}
              </td>
              <td>{customer.activationId}</td>
              <td>
                <div className="d-flex justify-content-evenly">
                  <i className="bi bi-trash3 text-secondary fs-5"></i>
                  <i className="bi bi-pencil-square text-danger fs-5"></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  ) : (
    <p className="text-danger">No hay usuarios para mostrar</p>
  );
}

export default Customers;

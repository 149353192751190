import { createSlice } from "@reduxjs/toolkit";

const pageSlice = createSlice({
  name: "page",
  initialState: { firstTimeInPage: true, hideWelcomeModal: false },
  reducers: {
    dontShowAgainWelcomeModal(state, action) {
      state.hideWelcomeModal = !state.hideWelcomeModal;
    },
    hideWelcomeModal(state, action) {
      state.firstTimeInPage = false;
    },
    showWelcomeModalNextTime(state, action) {
      state.firstTimeInPage = true;
    },
    resetPageSlice(state, action) {
      return { firstTimeInPage: true, hideWelcomeModal: false };
    },
  },
});

const { actions, reducer } = pageSlice;
export const {
  dontShowAgainWelcomeModal,
  hideWelcomeModal,
  showWelcomeModalNextTime,
  resetPageSlice,
} = actions;
export default reducer;

import Table from "react-bootstrap/Table";
import axios from "axios";
import { useState, useEffect } from "react";

function Users() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/usuarios`
      );
      setUsers(response.data);
    };
    getUsers();
  }, []);

  return users.length ? (
    <div>
      <h1 className="text-center my-3">Usuarios</h1>
      <Table responsive striped bordered hover className="p-0 m-0">
        <thead className="bg-dark text-light">
          <tr className="text-center">
            <th className="idTableField">Id</th>
            <th>Es admin?</th>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Alias</th>
            <th>Teléfono</th>
            <th>Correo</th>
            <th>Activaciones</th>
            <th>Registrado</th>
            <th>Modificado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td className="idTableField">{user.id}</td>
              <td>{user.role}</td>
              <td>{user.firstname}</td>
              <td>{user.lastname}</td>
              <td>{user.username}</td>
              <td>{user.phone}</td>
              <td>{user.email}</td>
              <td>{user.activations.length}</td>
              <td>{new Date(user.createdAt).toLocaleDateString("es-ES")}</td>
              <td>{new Date(user.updatedAt).toLocaleDateString("es-ES")}</td>
              <td>
                <div className="d-flex justify-content-evenly">
                  <i className="bi bi-trash3 text-secondary fs-5"></i>
                  <i className="bi bi-pencil-square text-danger fs-5"></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  ) : (
    <p className="text-danger">No hay usuarios para mostrar</p>
  );
}

export default Users;

import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";

function AdminMenu({ show, handleClose }) {
  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="mx-auto">Menú</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="offCanvasMenuList">
            <li onClick={handleClose}>
              <Link to="/">
                <i className="bi bi-bell fs-5 me-2"></i>Avisar
              </Link>
            </li>
            <hr />

            <li onClick={handleClose}>
              <Link to="/ventas/dashboard">
                <i className="bi bi-graph-up-arrow fs-5 me-2"></i>Dashboard
              </Link>
            </li>
            <hr />
            <li onClick={handleClose}>
              <Link to="/ventas/activaciones">
                <i className="bi bi-key fs-5 me-2"></i>Mis Códigos
              </Link>
            </li>
            <hr />
            <li onClick={handleClose}>
              <Link to="/ventas/perfil">
                <i className="bi bi-person-lines-fill fs-5 me-2"></i>Mis Datos
              </Link>
            </li>
            <hr />
            <li onClick={handleClose}>
              <Link to="/legal">
                <i className="bi bi-briefcase fs-5 me-2"></i>Términos y
                Condiciones
              </Link>
            </li>
            <hr />
            <li onClick={handleClose}>
              <Link to="/sobre_nosotros">
                <i className="bi bi-info-circle fs-5 me-2"></i>Sobre Nosotros
              </Link>
            </li>
            <hr />
            <li onClick={handleClose}>
              <Link to="/preguntas_frecuentes">
                <i className="bi bi-question-circle fs-5 me-2"></i>Preguntas
                Frecuentes
              </Link>
            </li>
            <hr />
            <li onClick={handleClose}>
              <Link to="/ventas/logout">
                <i className="bi bi-box-arrow-left fs-5 me-2"></i>Salir
              </Link>
            </li>
            <hr />
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default AdminMenu;

import axios from "axios";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

function PresetsVehicleModal({ modalShow, onHide }) {
  const [presets, setPresets] = useState([]);
  useEffect(() => {
    const getPresets = async () => {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/predefinidos_vehiculos`,
      });
      setPresets(response.data);
    };
    getPresets();
  }, []);

  return (
    <Modal
      show={modalShow}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          PREDEFINIDOS
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {presets.length > 0 && (
          <ul>
            {presets.map((preset) => (
              <li key={preset.id}>{preset.text.toUpperCase()}</li>
            ))}
          </ul>
        )}
        <p>
          LOS USUARIOS PODRÁN ELEGIR UNA DE LOS OPCIONES LISTADAS CUANDO DESEEN
          AVISARLE DE ALGUNA SITUACIÓN REFERENTE A SU VEHÍCULO.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={onHide} className="btn btn-dark">
          CERRAR
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default PresetsVehicleModal;

import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { Spinner } from "react-bootstrap";
import CustomTooltip from "../CustomTooltip";

function Profile() {
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const user = useSelector((state) => state.user);
  const [profile, setProfile] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [validatingLoader, setValidatingLoader] = useState(false);

  useEffect(() => {
    const getProfile = async () => {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/api/clientes/profile`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setProfile(response.data);
      setName(response.data.name);
      setEmail(response.data.email);
      setPhone(response.data.phone);
    };
    getProfile();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidatingLoader(true);
    const requiredValues = [name, email, phone];
    if (requiredValues.includes("")) {
      setValidatingLoader(false);
      return toast.error("DEBE COMPLETAR NOMBRE, EMAIL Y TELÉFONO.");
    }
    const response = await axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/api/clientes`,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      data: { name, email, password, phone },
    });
    if (response) {
      setValidatingLoader(false);
      return toast.success("¡SUS DATOS FUERON ACTUALIZADOS!");
    }
    setValidatingLoader(false);
    return toast.error(
      "ALGO SALIÓ MAL, VERIFIQUE O VUELVA A INTENTAR EN UNOS MINUTOS."
    );
  };

  const [showTip, setShowTip] = useState(false);
  const [tollTipText, setTollTipText] = useState("");
  const [helpTipPlacement, setHelpTipPlacement] = useState("");
  const target = useRef(null);

  const handleTip = (text, selected, placement) => {
    target.current = selected.target;
    setTollTipText(text);
    setHelpTipPlacement(placement);
    setShowTip(!showTip);
  };

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (showTip && target.current !== e.target) setShowTip(false);
    });
    return () => {
      document.removeEventListener("click", (e) => {
        if (showTip && target.current !== e.target) setShowTip(false);
      });
    };
  });

  return profile ? (
    <div>
      <div className="customerProfile w-100">
        <h1 className="fs-1 py-2">PERFIL</h1>
        <p className="text-center fs-6">
          CÓDIGO DE ACTIVACIÓN:{" "}
          <span className="fw-semibold text-nowrap">
            {profile.activation.code.toUpperCase()}
            <i
              className="bi bi-question-circle-fill ms-2 helpTip"
              ref={target}
              onClick={(e) =>
                handleTip(
                  "SERÁ NECESARIO AL RESTABLECER CONTRASEÑA. TAMBIÉN PUEDE QUE SE LE SOLICITE PARA GESTIONES SOBRE SU SERVICIO.",
                  e,
                  "bottom"
                )
              }
            ></i>
          </span>
        </p>
        <p className="text-center">
          IDENTIFICADOR: <strong>{profile.id}</strong>{" "}
          <i
            className="bi bi-question-circle-fill ms-2 helpTip"
            ref={target}
            onClick={(e) =>
              handleTip(
                "FACILITE ESTE VALOR A CADA PERSONA QUE RECOMIENDE. ES NECESARIO PARA EL REGISTRO DE REFERIDOS Y EVITAR ASÍ EXPONER SU DATOS PERSONALES.",
                e,
                "bottom"
              )
            }
          ></i>
        </p>
        <form action="" onSubmit={handleSubmit}>
          <TextField
            label={
              <span>
                <i className="bi bi-person fs-4 me-2"></i>NOMBRE DE PILA
              </span>
            }
            variant="standard"
            fullWidth
            inputProps={{
              maxLength: 10,
              style: { fontSize: 18, letterSpacing: "2px" },
            }}
            className="my-3"
            value={name.toUpperCase()}
            onChange={(e) => {
              setName(e.target.value.trim().toUpperCase());
              setSubmitDisabled(false);
            }}
          />
          <TextField
            label={
              <span>
                <i className="bi bi-envelope-at fs-4 me-2"></i>E-MAIL
              </span>
            }
            variant="standard"
            fullWidth
            inputProps={{ style: { fontSize: 16, letterSpacing: "2px" } }}
            className="mb-3"
            value={email.toUpperCase()}
            multiline
            onChange={(e) => {
              setEmail(e.target.value.trim().toUpperCase());
              setSubmitDisabled(false);
            }}
          />
          <TextField
            label={
              <span>
                <i className="bi bi-phone-fill fs-4 me-2"></i>TELÉFONO
              </span>
            }
            id="input-with-sx"
            variant="standard"
            type="number"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              style: { fontSize: 18, letterSpacing: "2px" },
            }}
            className="mb-3"
            value={phone}
            fullWidth
            onChange={(e) => {
              setPhone(e.target.value.trim());
              setSubmitDisabled(false);
            }}
          />
          {showPasswordInput && (
            <>
              <p className="pt-3 mb-0">
                INGRESE NUEVA CONTRASEÑA Y PRESIONE EL BOTÓN{" "}
                <strong>GUARDAR CAMBIOS</strong> PARA CONFIRMAR.
              </p>
              <TextField
                label={
                  <span>
                    <i className="bi bi-key fs-4 me-2"></i>
                    NUEVA CONTRASEÑA
                  </span>
                }
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: 16, letterSpacing: "2px" } }}
                className="mb-3"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value.trim());
                  setSubmitDisabled(false);
                }}
              />
            </>
          )}

          {showPasswordInput ? (
            <button
              type="button"
              onClick={() => {
                setShowPasswordInput((prev) => !prev);
                setPassword("");
              }}
              className="btn btn-outline-dark w-100 rounded-pill mt-3 mb-4"
            >
              NO CAMBIAR CONTRASEÑA
            </button>
          ) : (
            <button
              type="button"
              onClick={() => setShowPasswordInput((prev) => !prev)}
              className="btn btn-outline-dark w-100 rounded-pill mt-3 mb-4"
            >
              CAMBIAR CONTRASEÑA
            </button>
          )}

          {validatingLoader ? (
            <button
              id="login-btn"
              type="button"
              className="btn btn-dark rounded-pill w-100 mt-3 shadow confirmButton d-flex justify-content-center align-items-center"
            >
              <Spinner animation="grow" variant="light" className="me-3" />
              GUARDANDO...
            </button>
          ) : (
            <button
              type="submit"
              id="login-btn"
              disabled={submitDisabled}
              className="btn btn-dark rounded-pill w-100 mt-3 shadow confirmButton"
            >
              GUARDAR CAMBIOS
            </button>
          )}
        </form>
        <hr />
        <Link
          className="text-primary d-block text-center fw-semibold"
          to="/clientes/dashboard"
        >
          VOLVER
        </Link>
      </div>
      <CustomTooltip
        showTip={showTip}
        tollTipText={tollTipText}
        target={target}
        placement={helpTipPlacement}
      />
    </div>
  ) : (
    <Loader />
  );
}

export default Profile;

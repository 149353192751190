import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./Customer.module.css";
import { useDispatch } from "react-redux";
import { login } from "../../redux/userSlice";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function Login() {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [error, setError] = useState("");
  const [validatingLoader, setValidatingLoader] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const requiredValues = [phone, password];
    if (requiredValues.includes(""))
      return toast.error("DEBE INGRESAR TELÉFONO Y CONTRASEÑA");
    setValidatingLoader(true);
    const response = await axios({
      url: process.env.REACT_APP_API_URL + "/api/clientes/tokens",
      method: "post",
      data: { phone, password },
    });

    const { token, role, user, id } = response.data;

    if (response.data.token && role === "customer") {
      dispatch(
        login({
          token,
          role,
          user,
          id,
        })
      );
      setValidatingLoader(false);
      toast.success("HAS INICIADO SESIÓN. !BIENVENIDO/A!");
      return navigate("/clientes/dashboard");
    }
    toast.error("CREDENCIALES INCORRECTAS, VERIFIQUE.");
    setValidatingLoader(false);
  };

  return (
    <div>
      <h1 className="text-center mb-4 welcomeTitle">¡BIENVENIDO/A!</h1>
      <p className="text-center fs-6 text-dark fw-semibold mb-4">
        PARA INICIAR SESIÓN UTILICE LAS CREDENCIALES QUE INGRESÓ AL REGISTRARSE.
      </p>
      <div className={styles.formContainer}>
        <form action="" onSubmit={handleSubmit}>
          <div className="mb-4">
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <TextField
                id="phone"
                autoComplete="off"
                label={
                  <span>
                    <i className="bi bi-phone-fill me-2 fs-4"></i>TELÉFONO
                  </span>
                }
                variant="standard"
                type="number"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  style: { fontSize: "1.3rem", fontWeight: "500" },
                }}
                onFocus={() => setError("")}
                value={phone}
                onChange={(e) => setPhone(e.target.value.trim())}
                className={styles.customerLoginInput}
              />
            </Box>
          </div>
          <div className="text-center mb-4 position-relative">
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <TextField
                id="password"
                autoComplete="off"
                label={
                  <span>
                    {" "}
                    <i className="bi bi-shield-lock fs-4 me-2"></i>
                    CONTRASEÑA
                  </span>
                }
                variant="standard"
                type={hidePassword ? "password" : "text"}
                inputProps={{
                  style: {
                    fontSize: "1.3rem",
                    fontWeight: "500",
                    width: "100%",
                  },
                }}
                onFocus={() => setError("")}
                value={password}
                onChange={(e) => setPassword(e.target.value.trim())}
                className={styles.customerLoginInput}
              />
              {error && (
                <p className={`text-danger ${styles.loginErrorContainer}`}>
                  {error}
                </p>
              )}
            </Box>
            {password && !hidePassword && (
              <VisibilityOffIcon
                sx={{
                  color: "action.active",
                  mr: 1,
                  my: 0.5,
                  position: "absolute",
                  right: "-10px",
                  bottom: "15px",
                  fontSize: 28,
                }}
                onClick={() => setHidePassword(true)}
              />
            )}
            {password && hidePassword && (
              <VisibilityIcon
                sx={{
                  color: "action.active",
                  mr: 1,
                  my: 0.5,
                  position: "absolute",
                  right: "-10px",
                  bottom: "15px",
                  fontSize: 28,
                }}
                onClick={() => setHidePassword(false)}
              />
            )}
          </div>
          <div className="text-center">
            {validatingLoader ? (
              <button
                id="login-btn"
                type="button"
                className="py-2 px3 mt-3 btn btn-dark shadow-lg fs-5 w-100 d-flex justify-content-center"
              >
                <Spinner animation="grow" variant="light" className="me-3" />
                INICIANDO...
              </button>
            ) : (
              <button
                type="submit"
                id="login-btn"
                className="py-2 px3 mt-3 btn btn-dark shadow-lg fs-5 w-100 d-flex justify-content-center"
              >
                INICIAR SESIÓN
              </button>
            )}
          </div>
        </form>
        <hr className="mt-4" />

        <Link
          to="/clientes/registro"
          className="btn btn-outline-dark fs-6 d-flex justify-content-center align-items-center"
        >
          <i class="bi bi-person me-2 fs-5"></i>REGISTRARME
        </Link>
        <Link
          to="/clientes/restablecer"
          className="text-primary fs-6 d-flex justify-content-center mt-3"
        >
          OLVIDÉ MI CONTRASEÑA
        </Link>
      </div>
    </div>
  );
}

export default Login;

import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";

import { toast } from "react-toastify";
import Loader from "../Loader";
import { Spinner } from "react-bootstrap";
import CustomTooltip from "../CustomTooltip";

function OneVehicle() {
  const [plateHasChanged, setPlateHasChanged] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const [presets, setPresets] = useState([]);
  const [plate, setPlate] = useState("");
  const [alias, setAlias] = useState("");
  const [alternative_number, setAlternative_number] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [validatingLoader, setValidatingLoader] = useState(false);

  const [showTip, setShowTip] = useState(false);
  const [tollTipText, setTollTipText] = useState("");
  const target = useRef(null);

  const handleTip = (text, selected) => {
    target.current = selected.target;
    setTollTipText(text);
    setShowTip(!showTip);
  };

  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (showTip && target.current !== e.target) setShowTip(false);
    });
    return () => {
      document.removeEventListener("click", (e) => {
        if (showTip && target.current !== e.target) setShowTip(false);
      });
    };
  });

  useEffect(() => {
    const getVehicle = async () => {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/api/vehiculos/${
          !plateHasChanged ? id : plate
        }`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const response2 = await axios({
        url: `${process.env.REACT_APP_API_URL}/api/predefinidos_vehiculos`,
        method: "GET",
      });
      //console.log(response.data.createdAt);
      setVehicle(response.data);

      setPlate(response.data.plate.toUpperCase());
      setAlias(response.data.alias.toUpperCase());
      setAlternative_number(response.data.alternative_number);

      const presetsWithActiveProperty = response2.data.map((item) =>
        response.data.vehicle_presets.some((elem) => elem.id === item.id)
          ? { ...item, active: true }
          : { ...item, active: false }
      );

      setPresets(presetsWithActiveProperty.sort((a, b) => b.active - a.active));
    };
    getVehicle();
    // eslint-disable-next-line
  }, []);

  const handlePresets = (item) => {
    setPresets(
      presets.map((preset) => {
        return preset.id !== item.id
          ? preset
          : { ...preset, active: !preset.active };
      })
    );
    setSubmitDisabled(false);
  };

  const handleAllPresets = () => {
    if (presets.every((preset) => preset.active)) {
      setPresets(presets.map((preset) => ({ ...preset, active: false })));
    } else {
      setPresets(presets.map((preset) => ({ ...preset, active: true })));
    }
    setSubmitDisabled(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const messages = [];
    for (const preset of presets) {
      if (preset.active) {
        messages.push(preset.id);
      }
    }

    if (messages.length === 0)
      return toast.error("DEBE HABILITAR AL MENOS 1 MENSAJE.");

    const requiredValues = [plate];
    if (requiredValues.includes(""))
      return toast.error("DEBE INGRESAR MATRÍCULA.");

    setValidatingLoader(true);

    const response = await axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/api/vehiculos/${vehicle.id}`,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      data: { plate, alias, alternative_number, messages },
    });
    if (response) {
      setValidatingLoader(false);
      toast.success(`SE HA ACTUALIZADO ${vehicle.alias.toUpperCase()}.`);
      navigate("/clientes/vehiculos");
    }
  };

  return (
    <div className="d-flex flex-column">
      <h1 className="fs-1 text-center">VEHÍCULO</h1>
      {vehicle && presets.length ? (
        <div className="customerVehicle w-100">
          <form action="" onSubmit={handleSubmit}>
            <div className="position-relative">
              <TextField
                label={
                  <span>
                    <i className="bi bi-car-front fs-4 me-2"></i>MATRÍCULA
                  </span>
                }
                value={plate}
                variant="standard"
                fullWidth
                inputProps={{
                  maxLength: 15,
                  style: { fontSize: 20, letterSpacing: "3px" },
                }}
                className="my-3"
                onChange={(e) => {
                  e.target.value.length <= 15 &&
                    setPlate(e.target.value.trim().toUpperCase());
                  setPlateHasChanged(true);
                  setSubmitDisabled(false);
                }}
              />
              <i
                className="bi bi-question-circle-fill ms-2 position-absolute helpTip"
                ref={target}
                onClick={(e) =>
                  handleTip(
                    "AQUÍ PUEDE MODIFICAR LA MATRÍCULA DEL VEHÍCULO.",
                    e
                  )
                }
              ></i>
            </div>
            <div className="position-relative">
              <TextField
                label={
                  <span>
                    <i className="bi bi-diamond-half fs-4 me-2"></i>ALIAS
                  </span>
                }
                value={alias}
                variant="standard"
                fullWidth
                inputProps={{
                  maxLength: 12,
                  style: { fontSize: 20, letterSpacing: "3px" },
                }}
                className="mb-3"
                onChange={(e) => {
                  e.target.value.length <= 12 &&
                    setAlias(e.target.value.trim().toUpperCase());
                  setSubmitDisabled(false);
                }}
              />
              <i
                className="bi bi-question-circle-fill ms-2 position-absolute helpTip"
                ref={target}
                onClick={(e) =>
                  handleTip(
                    "AQUÍ PUEDE MODIFICAR O BORRAR EL ALIAS DEL VEHÍCULO. (MÁXIMO 12 CARACTERES)",
                    e
                  )
                }
              ></i>
            </div>
            <div className="position-relative mb-4">
              <TextField
                label={
                  <span>
                    <i className="bi bi-phone-fill fs-4 me-2"></i>NUMERO
                    ALTERNATIVO
                  </span>
                }
                id="input-with-sx"
                variant="standard"
                type="number"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  style: { fontSize: 20, letterSpacing: "3px" },
                }}
                className="mb-3"
                value={alternative_number}
                fullWidth
                onChange={(e) => {
                  e.target.value.length <= 15 &&
                    setAlternative_number(e.target.value.trim());
                  setSubmitDisabled(false);
                }}
              />
              <i
                className="bi bi-question-circle-fill ms-2 position-absolute helpTip"
                ref={target}
                onClick={(e) =>
                  handleTip(
                    "AQUÍ PUEDE MODIFICAR O BORRAR EL NÚMERO DE CELULAR ALTERNATIVO, SI LO HACE RECIBIRÁ LAS NOTIFICACIONES ÚNICAMENTE EN SU CELULAR.",
                    e
                  )
                }
              ></i>
            </div>
            <div className="d-flex justify-content-between generalListSwitch mb-2">
              <h2 className="fs-5 mb-0 pb-0 d-flex align-items-center me-2">
                SELECCIONE MENSAJES QUE PODRÁN ENVIARLE
              </h2>
              <i
                className={
                  presets.every((preset) => preset.active)
                    ? "bi bi-check-circle-fill text-success d-flex align-items-center"
                    : "bi bi-circle text-secondary d-flex align-items-center"
                }
                onClick={handleAllPresets}
              ></i>
            </div>
            {presets.map((preset) => (
              <div
                key={preset.id}
                className="d-flex align-items-center position-relative"
              >
                <button
                  type="button"
                  className={
                    preset.active
                      ? "btn btn-outline-dark rounded-pill myButtonList"
                      : "btn btn-outline-secondary text-decoration-line-through text-secondary myButtonList rounded-pill"
                  }
                >
                  {preset.text.toUpperCase()}
                </button>
                <i
                  onClick={(e) => handlePresets(preset)}
                  className={
                    preset.active
                      ? "bi bi-check-circle-fill text-success listSwitch"
                      : "bi bi-circle text-secondary listSwitch"
                  }
                ></i>
              </div>
            ))}

            {validatingLoader ? (
              <button
                id="login-btn"
                type="button"
                className="btn btn-dark rounded-pill w-100 mt-3  shadow confirmButton"
              >
                <span className="d-flex align-items-center justify-content-center">
                  <Spinner animation="grow" variant="light" className="me-3" />
                  GUARDANDO...
                </span>
              </button>
            ) : (
              <button
                type="submit"
                id="login-btn"
                disabled={submitDisabled}
                className="btn btn-dark rounded-pill w-100 mt-3 shadow confirmButton"
              >
                GUARDAR CAMBIOS
              </button>
            )}
          </form>
          <hr />
          <div className="text-center mb-4">
            <Link className="text-primary fw-semibold" to="/clientes/dashboard">
              VOLVER
            </Link>
          </div>
          <CustomTooltip
            showTip={showTip}
            tollTipText={tollTipText}
            target={target}
          />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default OneVehicle;

import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../Loader";

function Logs() {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);
  useEffect(() => {
    const getLogs = async () => {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/api/registros`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setLogs(response.data);
      setLoading(false);
    };
    getLogs();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="d-flex flex-column p-3 shadow rounded w-100 customerLogs">
      <h1 className="fs-1 text-center">HISTORIAL</h1>
      {!loading && (
        <p className="mt-3 text-dark">
          Mensajes recibidos: {Number(logs.length)}
        </p>
      )}

      {!loading && logs.length === 0 && (
        <p className="mt-2">AUN NO HA RECIBIDO MENSAJES.</p>
      )}
      <hr />
      {logs.length > 0 &&
        logs.map((log) => (
          <div key={log.id}>
            <p>MENSAJE: {log.details.text.toUpperCase()}</p>
            <p>MAT/COD: {log.details.sentTo}</p>
            <p>
              ENVIADO A: {log.receiver[0]}{" "}
              {log.receiver[1] && ` y ${log.receiver[1]}`}
            </p>
            <p>TIPO: {log.type.toUpperCase()}</p>
            <p>FECHA: {new Date(log.createdAt).toLocaleDateString("es-ES")}</p>
            <hr />
          </div>
        ))}
      {loading ? (
        <Loader />
      ) : (
        <Link
          className="text-center d-block text-primary fw-semibold"
          to="/clientes/dashboard"
        >
          VOLVER
        </Link>
      )}
    </div>
  );
}

export default Logs;

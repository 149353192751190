import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { resetPageSlice } from "../redux/pageSlice";
import { resetUserSlice } from "../redux/userSlice";

function ResetLS() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(resetUserSlice());
    dispatch(resetPageSlice());
    navigate("/avisar");
    // eslint-disable-next-line
  }, []);
  return <div>ResetLS</div>;
}

export default ResetLS;

import { useState } from "react";
import { useParams } from "react-router-dom";
import mercadoPagoLogo from "../../img/mercadoPagoLogo.webp";
import brouLogo from "../../img/brouLogo.png";
import pagoSeguroLogo from "../../img/pagoSeguroLogo.png";
import sslLogo from "../../img/sslLogo.png";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function Checkout() {
  const [brou, setBrou] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mercadoPago, setMercadoPago] = useState(false);
  const params = useParams();
  const handleBrou = () => {
    if (!brou) {
      setBrou(true);
      setMercadoPago(false);
    }
  };

  const handleMercadoPago = () => {
    if (!mercadoPago) {
      setMercadoPago(true);
      setBrou(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!mercadoPago && !brou)
      return toast.error("DEBE SELECCIONAR UN MÉTODO DE PAGO PARA CONTINAR.");
    setIsLoading(true);
    if (mercadoPago) {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/compras`,
        data: { paymentMethod: "mercadopago", quantity: 1 },
      });
      if (response) {
        return window.location.replace(response.data.url);
      }
      return toast.error(
        "ALGO SALIÓ MAL, INTENTA NUEVAMENTE EN UNOS MINUTOS O PONTE EN CONTACTO PARA RESOLVER."
      );
    }
    setIsLoading(false);
    return toast.info(
      "ACEPTAMOS DEPÓSITOS O TRANSFERENCIA A CUENTA BROU COMUNICATE POR EMAIL O WHATSAPP POR MÁS DETALLES."
    );
  };

  return (
    <div className="checkout">
      <h1 className="text-center">NUEVO SERVICIO</h1>
      <hr />
      {params.producto === "sms" && (
        <>
          <div className="border pt-3 px-3 mb-4 details">
            <div className="fs-5">
              PRODUCTO: <span className="fw-semibold">AVISALE SMS</span>
              <br />
              VALOR: $<span className="fw-semibold fs-5">250</span>
              <span className="text-danger text-decoration-line-through ms-2">
                $500
              </span>
            </div>
            <hr />
            <h5 className="fs-5 text-center">ELIGE TU FORMA DE PAGO</h5>
            <form action="" className="my-4" onSubmit={handleSubmit}>
              <div className="mb-3 d-flex align-items-center justify-content-start">
                <input
                  type="radio"
                  name="producto"
                  checked={mercadoPago}
                  onChange={handleMercadoPago}
                  id="mercadopago"
                  className="me-3"
                />
                <label htmlFor="mercadopago" className="w-100">
                  <img
                    src={mercadoPagoLogo}
                    height="40px"
                    alt="Logo de MercadoPago"
                  />
                </label>
              </div>
              <div className="mb-4 d-flex align-items-center justify-content-start">
                <input
                  type="radio"
                  name="producto"
                  id="brou"
                  className="me-3"
                  checked={brou}
                  onChange={handleBrou}
                />
                <label
                  htmlFor="brou"
                  className="w-100 d-flex align-items-center"
                >
                  <img src={brouLogo} height="40px" alt="Logo BROU" />
                </label>
              </div>
              <div className="text-end my-3">
                <img src={sslLogo} height="41px" alt="Logo SSL" />
                <img
                  src={pagoSeguroLogo}
                  height="37px"
                  alt="Logo Pago Seguro"
                />
              </div>
              {isLoading ? (
                <button
                  type="button"
                  className="btn btn-dark w-100 mt-3 fs-3 d-flex justify-content-center"
                >
                  <Spinner animation="grow" variant="light" className="me-3" />
                  REDIRIGIENDO...
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-dark w-100 mt-3 fs-3 d-flex justify-content-center"
                >
                  CONTINUAR
                </button>
              )}
            </form>

            <hr />
            <p className="productCardNote">
              PRECIO EXPRESADO EN PESOS URUGUAYOS IVA INCLUIDO.
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default Checkout;

import axios from "axios";
import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";

function Vehicles() {
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    const getVehicles = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/vehiculos`
      );
      setVehicles(response.data);
    };
    getVehicles();
  }, []);

  return vehicles.length ? (
    <div>
      <h1 className="text-center my-3">Vehículos</h1>
      <Table responsive striped bordered hover className="p-0 m-0">
        <thead className="bg-dark text-light">
          <tr className="text-center">
            <th className="idTableField">Id</th>
            <th>Matrícula</th>
            <th>Alias</th>
            <th>Nº Alternativo</th>
            <th>Registrado</th>
            <th>Modificado</th>
            <th>Cliente</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {vehicles.map((vehicle) => (
            <tr key={vehicle.id}>
              <td className="idTableField">{vehicle.id}</td>
              <td>{vehicle.plate}</td>
              <td>{vehicle.alias}</td>
              <td>{vehicle.alternative_number}</td>
              <td>{new Date(vehicle.createdAt).toLocaleDateString("es-ES")}</td>
              <td>{new Date(vehicle.updatedAt).toLocaleDateString("es-ES")}</td>
              <td>{vehicle.customerId}</td>
              <td>
                <div className="d-flex justify-content-evenly">
                  <i className="bi bi-trash3 text-secondary fs-5"></i>
                  <i className="bi bi-pencil-square text-danger fs-5"></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  ) : (
    <p className="text-danger">No hay vehículos para mostrar.</p>
  );
}

export default Vehicles;

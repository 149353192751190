import avisaleLogo from "../../img/avisaleLogo.png";
import "./NotFound.css";

function NotFound() {
  return (
    <div
      id="notFoundPage"
      className="text-center d-flex flex-column align-items-center justify-content-center"
    >
      <img src={avisaleLogo} alt="Logo de avisale.com.uy" className="mb-5" />
      <h1 className="pt-5">
        Error 404 - Página no econtrada, verifique o contacte al proveedor.
      </h1>
    </div>
  );
}

export default NotFound;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import styles from "../customer/Customer.module.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom/dist";

function IndexStepOne() {
  const [plate, setPlate] = useState("");
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!plate && !code)
      return toast.error("DEBE INGRESAR MATRÍCULA O CÓDIGO.");
    plate ? navigate(`/vehiculos/${plate}`) : navigate(`/propiedades/${code}`);
  };
  return (
    <div className="homePage">
      <div>
        <h1 className="text-center fs-1">AVISAR</h1>
        <hr />
        <p className="text-dark fs-5">
          AVISALE AL RESPONSABLE DEL VEHÍCULO O PROPIEDAD{" "}
          <strong> EN 3 SIMPLES PASOS</strong> Y <strong>SIN COSTO</strong>.
        </p>
        <ol className="text-start fs-5">
          <li className="fs-6">INGRESA MATRÍCULA O CÓDIGO.</li>
          <li className="fs-6">SELECCIONA MENSAJE.</li>
          <li className="fs-6">CONFIRMA ENVÍO.</li>
        </ol>
        <hr />
      </div>
      <form action="" onSubmit={handleSubmit}>
        {!code && (
          <div className="mb-4">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                id="plate"
                name="plate"
                label={
                  <span style={{ fontSize: "1.1rem" }}>
                    <i className="bi bi-car-front fs-4 me-2"></i>
                    MATRÍCULA
                  </span>
                }
                variant="standard"
                value={plate}
                inputProps={{
                  style: { fontSize: "1.7rem", fontWeight: "500" },
                }}
                onChange={(e) =>
                  !code && setPlate(e.target.value.trim().toUpperCase())
                }
                className={styles.customerLoginInput}
              />
            </Box>
          </div>
        )}
        {!plate && (
          <div className="text-center mb-4 position-relative">
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                position: "relative",
              }}
            >
              <TextField
                id="code"
                name="code"
                variant="standard"
                label={
                  <span style={{ fontSize: "1.1rem" }}>
                    {" "}
                    <i className="bi bi-buildings fs-4 me-2"></i>
                    CÓDIGO PROPIEDAD
                  </span>
                }
                inputProps={{
                  style: { fontSize: "1.7rem", fontWeight: "500" },
                }}
                value={code}
                onChange={(e) => {
                  !plate && setCode(e.target.value.trim().toUpperCase());
                }}
                className={styles.customerLoginInput}
              />
            </Box>
          </div>
        )}
        <div className="text-center">
          <button
            type="submit"
            id="login-btn"
            className="py-2 px3 mt-3 btn btn-dark shadow-lg fs-5 w-100"
          >
            VERIFICAR{" "}
          </button>
        </div>
      </form>
      <hr />
      <Link
        to="/clientes/registro"
        className="btn btn-outline-dark fs-6 d-flex justify-content-center align-items-center"
      >
        <i class="bi bi-person me-2 fs-5"></i>REGISTRARME
      </Link>
    </div>
  );
}

export default IndexStepOne;

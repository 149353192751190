import { Link } from "react-router-dom";

function AboutThisProject() {
  return (
    <div className="AboutThisProyect">
      <h1 className="text-center fs-2 mb-4">SOBRE NOSOTROS</h1>
      <div className="aboutPrimaryColor p-4">
        <h3>¿Quiénes somos?</h3>
        <p className="fs-5">
          Somos una empresa uruguaya compuesta por un pequeño equipo de
          apasionados por la tecnología, dedicados a crear soluciones
          innovadoras que faciliten la vida de las personas.
        </p>
        <p className="fs-5">
          Estamos orgullosos de haber desarrollado <strong>avisale.uy</strong>,
          la primera y única plataforma que resuelve la problemática de poner en
          contacto a cualquier persona con los responsables de vehículos o
          propiedades que necesiten atención inmediata.
        </p>
      </div>
      <div className="aboutSecondaryColor p-4">
        <h3>¿Por qué avisale.uy?</h3>
        <p className="fs-5">
          A muchos nos ha pasado llegar a nuestro vehículo o propiedad y
          encontrarnos con situaciones como no poder arrancar por haber dejado
          luces encendidas, encontrarnos con un vidrio roto, haber quedado mal
          estacionado u obstruyendo una salida, encontrar fugas de gases o
          líquidos, etc.
        </p>
        <p className="fs-5">
          En muchos otros casos hemos visto vehículos o propiedades que
          necesitan atención, pero no sabemos cómo contactar a su responsable
          para informarle de la situación.
        </p>
        <p className="fs-5">
          Es aquí donde nuestra aplicación se convierte en la solución perfecta
          para avisar sin costo a dicha persona sobre cualquier necesidad que su
          propiedad o vehículo presente, como las antes mencionadas.
        </p>
      </div>
      <div className="aboutPrimaryColor p-4">
        <h3>Características</h3>
        <p className="fs-5">
          Cada usuario registrado tendrá acceso a un panel donde podrá ver
          información de su servicio, agregar, modificar y quitar hasta tres
          vehículos y/o tres propiedades, con la posibilidad de aumentar esta
          cantidad.
        </p>
        <p className="fs-5">
          Las personas que avisen no necesitan conocer ningún dato de la persona
          que será notificada. Solo deberán proporcionar la matrícula del
          vehículo o el código de la propiedad para avisar de cualquier evento
          que requiera atención. Este servicio no tendrá costo para quien avisa,
          lo que significa que cualquier persona con acceso a internet puede
          usarlo sin preocuparse por los gastos.
        </p>
        <p className="fs-5">
          Al momento de avisar, solo se podrá enviar uno de los mensajes
          existentes en una lista predefinida por la persona que registró el
          vehículo o propiedad. Lo cual asegura que el servicio no podrá ser
          utilizado para publicidad, motivos personales u otros que puedan
          causar algún tipo de molestia.
        </p>
        <p className="fs-5">
          Una vez confirmado el envío, el dueño recibirá la correspondiente
          notificación en su celular. <br />
          Ejemplo de notificación:
          <br />
          <span className="fw-semibold">
            MATRICULA/CODIGO: SBS1234 (COCHE MAMA) - MOTIVO: LUCES ENCENDIDAS
          </span>
          .
        </p>
        <p className="fs-5">
          Entendemos la importancia de que la notificación llegue a su destino,
          por lo que implementamos el envío por dos proveedores diferentes. De
          esta forma logramos que si uno de ellos presenta algún inconveniente,
          el mensaje se envíe por el otro. En condiciones normales se recibirán
          ambos mensajes.
        </p>
      </div>
      <div className="aboutSecondaryColor p-4">
        <h3>Privacidad y Seguridad</h3>
        <p className="fs-5">
          En nuestra empresa valoramos la privacidad y la seguridad de cada
          parte involucrada. Por eso, nuestra aplicación está diseñada para
          proteger la identidad de quien reporta la situación y de la persona
          vinculada a la propiedad o vehículo. La privacidad es esencial para
          nosotros, por lo que mantenemos los datos de nuestros usuarios de
          manera confidencial y segura.
        </p>
      </div>
      <div className="aboutPrimaryColor p-4">
        <h3>Mejora Continua</h3>
        <p className="fs-5">
          Nos esforzamos constantemente por mejorar y actualizar nuestra
          aplicación para satisfacer las necesidades de nuestros usuarios.
          Nuestro equipo trabaja en forma permanente para ofrecer una
          experiencia fácil y confiable.
        </p>
      </div>
      <div className="aboutSecondaryColor p-4">
        <h3>Resumen</h3>
        <p className="fs-5">
          En resumen, somos una empresa uruguaya que ofrece soluciones
          tecnológicas innovadoras y que busca facilitar la vida de las
          personas. Nuestra aplicación es una herramienta útil y cuenta con una
          interfaz amigable para cualquier persona que necesite contactar al
          dueño de un vehículo o propiedad. La privacidad y la seguridad son
          esenciales para nosotros, por lo que nuestra aplicación está diseñada
          para proteger la identidad de nuestros usuarios. ¡Únete a nosotros y
          experimenta la tranquilidad de saber que cada persona que pueda notar
          algo será capaz de avisarte de forma rápida, sencilla, segura y sin
          costo para la misma!
        </p>
      </div>
      <div className="text-center my-3">
        <Link className="text-primary fw-semibold" to="/clientes/dashboard">
          Volver
        </Link>
      </div>
    </div>
  );
}

export default AboutThisProject;

import "./Dashboard.css";
import axios from "axios";
import { useState, useEffect } from "react";
import MyDatePicker from "./MyDatePicker";

function Dasboard() {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [totals, setTotals] = useState(null);

  useEffect(() => {
    const totalsConstructor = {};
    const filters = `count=1&fromDate=${fromDate}&toDate=${toDate}`;
    const getTotals = async () => {
      const response1 = await axios.get(
        `${process.env.REACT_APP_API_URL}/clientes?${filters}`
      );
      totalsConstructor.customers = {
        total: response1.data.customers,
        inRange: response1.data.inRangeCustomers,
      };

      const response4 = await axios.get(
        `${process.env.REACT_APP_API_URL}/activaciones?${filters}`
      );
      totalsConstructor.activations = {
        total: response4.data.activations,
        inRange: response4.data.inRangeActivations,
      };

      const response5 = await axios.get(
        `${process.env.REACT_APP_API_URL}/registros?${filters}`
      );
      totalsConstructor.logs = {
        total: response5.data.sent_msgs,
        inRange: response5.data.inRangeSentMsgs,
      };

      setTotals(totalsConstructor);
    };
    getTotals();
  }, [fromDate, toDate]);

  return totals ? (
    <div id="dashboard-container" className="text-center">
      <h1 className="mt-3">Panel de Estadísticas</h1>
      <hr />
      <h2 className="mb-3 fs-4">Seleccione Período</h2>
      <MyDatePicker
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
      />
      <div className="row mt-3">
        {" "}
        <div className="col-12 col-md-6 col-lg-4">
          <div className="dashboard_label1">
            <h3>Activaciones Vendidas</h3>
            <hr />
            <span>{totals.activations.total}</span>
            <hr />
            <p>
              En período:{" "}
              <strong className="fs-4">{totals.activations.inRange}</strong>
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <div className="dashboard_label4">
            <h3>Clientes Registrados</h3>
            <hr />
            <span>{totals.customers.total}</span>
            <hr />
            <p>
              En período:{" "}
              <strong className="fs-4">{totals.customers.inRange}</strong>
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <div className="dashboard_label3">
            <h3>Mensajes Enviados</h3>
            <hr />
            <span>{totals.logs.total}</span>
            <hr />
            <p>
              En período:{" "}
              <strong className="fs-4">{totals.logs.inRange}</strong>
            </p>
          </div>
        </div>
      </div>
      <hr />
    </div>
  ) : (
    <p>No hay valores para estadisticas</p>
  );
}

export default Dasboard;

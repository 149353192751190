import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import styles from "./Customer.module.css";

import Spinner from "react-bootstrap/Spinner";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Checkbox, InputLabel, NativeSelect } from "@mui/material";
import { green } from "@mui/material/colors";

import { toast } from "react-toastify";

import CustomTooltip from "../CustomTooltip";
import TermsInRegisterModal from "./TermsInRegisterModal";
import RegisterConfirmationModal from "./RegisterConfirmationModal";

function Register() {
  const searchParams = useSearchParams()[0];
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [codeValidated, setCodeValidated] = useState(false);
  const [activation, setActivation] = useState({
    code: searchParams.get("codigo") ? searchParams.get("codigo") : "",
    id: 0,
  });
  const [validatingLoader, setValidatingLoader] = useState(false);
  const [registeringLoader, setRegisteringLoader] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [reachedBy, setReachedBy] = useState("");
  const [recommendedBy, setRecommendedBy] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalConfirmShow, setModalConfirmShow] = useState(false);

  const navigate = useNavigate();

  const [showTip, setShowTip] = useState(false);
  const [tollTipText, setTollTipText] = useState("");
  const target = useRef(null);

  const handleTip = (text, selected) => {
    target.current = selected.target;
    setTollTipText(text);
    setShowTip(!showTip);
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const resetRegister = () => {
    setCodeValidated(false);
    setActivation({ ...activation, code: "" });
    setName("");
    setEmail("");
    setPhone("");
    setPassword("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setRegisteringLoader(true);
    const response = await axios({
      url: process.env.REACT_APP_API_URL + "/api/clientes",
      method: "post",
      data: {
        phone,
        email,
        name,
        password,
        activationId: activation.id,
        reachedBy,
        recommendedBy,
      },
    });
    if (response.data.isActive) {
      setRegisteringLoader(false);
      toast.success("REGISTRO EXITOSO, YA PUEDES INICIAR SESIÓN.");
      navigate("/clientes/login");
    } else {
      setRegisteringLoader(false);
      toast.error(response.data.errorMsg);
    }
  };

  const handleValidate = async (e) => {
    e && e.preventDefault();
    if (!activation.code)
      return toast.error("DEBES INGRESAR CÓDIGO PARA VALIDAR.");
    setValidatingLoader(true);
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL + "/api/activaciones/validate",
      data: { code: activation.code },
    });
    if (response.data.msg) {
      return setTimeout(() => {
        setValidatingLoader(false);
        toast.error(response.data.msg);
      }, 1300);
    }
    setTimeout(() => {
      toast.success("CÓDIGO VALIDADO, PUEDES CONTINUAR REGISTRO.");
      setActivation(response.data);
      setValidatingLoader(false);
      return setCodeValidated(true);
    }, 1000);
  };

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (showTip && target.current !== e.target) setShowTip(false);
    });
    return () => {
      document.removeEventListener("click", (e) => {
        if (showTip && target.current !== e.target) setShowTip(false);
      });
    };
  });

  useEffect(() => {
    if (searchParams.get("codigo")) handleValidate();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      onClick={() => showTip && setShowTip(false)}
      className="customerRegister"
    >
      <h1 className="text-center mb-4 welcomeTitle">
        {codeValidated ? "NUEVO USUARIO" : "¡BIENVENIDO/A!"}
      </h1>
      <p className="text-center fs-6 text-dark fw-semibold mb-4">
        {codeValidated
          ? "INGRESÁ TUS DATOS PARA REGISTRARSE COMO USUARIO. ESTOS DATOS SERÁN DE USO INTERNO Y NUNCA SERÁN VISIBLES PARA OTROS USUARIOS."
          : "PARA CONTINUAR INGRESÁ EL CÓDIGO DE ACTIVACIÓN OBTENIDO AL CONTRATAR EL SERVICIO."}
      </p>
      {codeValidated ? (
        <>
          <form action="" onSubmit={handleSubmit}>
            <div className="position-relative w-100">
              <TextField
                id="phone"
                label={
                  <span>
                    <i className="bi bi-phone-fill fs-4 me-2"></i>TELÉFONO
                  </span>
                }
                variant="standard"
                type="number"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  style: { fontSize: "1.3rem", fontWeight: "500" },
                }}
                value={phone}
                onChange={(e) =>
                  e.target.value.length <= 15 && setPhone(e.target.value.trim())
                }
                className={styles.customerLoginInput}
              />
              <i
                className="bi bi-question-circle-fill ms-2 position-absolute helpTip"
                ref={target}
                onClick={(e) =>
                  handleTip(
                    "INGRESE SU TELÉFONO, SERÁ EL NÚMERO AL CUAL LE LLEGARÁN LAS NOTIFICACIONES Y LO NECESITARÁ PARA INGRESAR A SU PANEL DE CONTROL (DASHBOARD).",
                    e
                  )
                }
              ></i>
            </div>
            <div className="position-relative w-100">
              <TextField
                id="email"
                label={
                  <span>
                    <i className="bi bi-envelope-at fs-4 me-2"></i>E-MAIL
                  </span>
                }
                variant="standard"
                value={email}
                multiline
                onChange={(e) => setEmail(e.target.value.trim().toUpperCase())}
                className={styles.customerLoginInput}
              />
              {email.length < 20 && (
                <i
                  className="bi bi-question-circle-fill ms-2 position-absolute helpTip"
                  ref={target}
                  onClick={(e) =>
                    handleTip(
                      "INGRESE SU EMAIL, SERÁ UN POSIBLE MEDIO DE CONTACTO EN CASO DE TENER QUE ENVIAR O SOLICITAR ALGUNA INFORMACIÓN.",
                      e
                    )
                  }
                ></i>
              )}
            </div>
            <div className="position-relative w-100">
              <TextField
                id="name"
                inputProps={{ maxLength: 10 }}
                label={
                  <span>
                    <i className="bi bi-person fs-4 me-2"></i>NOMBRE DE PILA
                  </span>
                }
                variant="standard"
                value={name}
                onChange={(e) => setName(e.target.value.trim().toUpperCase())}
                className={styles.customerLoginInput}
              />
              <i
                className="bi bi-question-circle-fill ms-2 position-absolute helpTip"
                ref={target}
                onClick={(e) =>
                  handleTip(
                    "INGRESE SU NOMBRE O APODO, SERÁ EL NOMBRE QUE APARECERÁ EN SU PANEL (DASHBOARD), Y COMO LE LLAMAREMOS EN CASO DE CONTACTARLE. (10 CARACTERES MÁXIMO)",
                    e
                  )
                }
              ></i>
            </div>
            <div className="position-relative w-100">
              <TextField
                id="password"
                label={
                  <span>
                    <i className="bi bi-key fs-4 me-2"></i>
                    CONTRASEÑA
                  </span>
                }
                variant="standard"
                type={hidePassword ? "password" : "text"}
                value={password}
                onChange={(e) =>
                  e.target.value.length <= 40 &&
                  setPassword(e.target.value.trim())
                }
                className={styles.customerLoginInput}
              />
              {!password && (
                <i
                  className="bi bi-question-circle-fill ms-2 position-absolute helpTip"
                  ref={target}
                  onClick={(e) =>
                    handleTip(
                      "INGRESE CONTRASEÑA, ES LA QUE DEBERA UTILIZAR PARA PODER ACCEDER A SU PANEL (DASHBOARD).",
                      e
                    )
                  }
                ></i>
              )}
              {password && !hidePassword && (
                <VisibilityOffIcon
                  sx={{
                    color: "action.active",
                    mr: 1,
                    my: 0.5,
                    position: "absolute",
                    right: "-10px",
                    bottom: "15px",
                    fontSize: 28,
                  }}
                  onClick={() => setHidePassword(true)}
                />
              )}
              {password && hidePassword && (
                <VisibilityIcon
                  sx={{
                    color: "action.active",
                    mr: 1,
                    my: 0.5,
                    position: "absolute",
                    right: "-10px",
                    bottom: "15px",
                    fontSize: 28,
                  }}
                  onClick={() => setHidePassword(false)}
                />
              )}
            </div>
            <div className="mt-3 shadow p-3 rounded-1 mb-3">
              <p className="text-center fs-6 text-dark fw-semibold mb-3">
                "¿CÓMO CONOCIÓ AVISALE.UY?"
              </p>
              <InputLabel
                variant="standard"
                htmlFor="uncontrolled-native"
                className="mb-1"
              >
                SELECCIONE AQUÍ
              </InputLabel>

              <NativeSelect
                onChange={(e) => setReachedBy(e.target.value.trim())}
                value={reachedBy}
                className={styles.customerLoginInput}
                inputProps={{
                  name: "age",
                  id: "uncontrolled-native",
                }}
              >
                <option value=""></option>
                <option value="RECOMENDADO">RECOMENDADO</option>
                <option value="GOOGLE">GOOGLE</option>
                <option value="INSTAGRAM">INSTAGRAM</option>
                <option value="FACEBOOK">FACEBOOK</option>
                <option value="INFORMATIVO">INFORMATIVO</option>
                <option value="FOLLETO">FOLLETO</option>
                <option value="STICKER">POR STICKER EN AUTO</option>
                <option value="CODIGO">POR CÓDIGO EN PROPIEDAD</option>
                <option value="OTROS">NINGUNA DE LAS ANTERIORES</option>
              </NativeSelect>
              {reachedBy === "RECOMENDADO" && (
                <div className="position-relative w-100 mt-3">
                  <TextField
                    id="recommendedBy"
                    inputProps={{ maxLength: 10 }}
                    label={
                      <span>
                        <i className="bi bi-person fs-4 me-2"></i>
                        IDENTIFICADOR
                      </span>
                    }
                    variant="standard"
                    value={recommendedBy}
                    onChange={(e) => setRecommendedBy(e.target.value.trim())}
                    className={styles.customerLoginInput}
                  />
                  <i
                    className="bi bi-question-circle-fill ms-2 position-absolute helpTip"
                    ref={target}
                    onClick={(e) =>
                      handleTip(
                        "INGRESE EL IDENTIFICADOR QUE LE INDICÓ EL USUARIO QUE RECOMENDÓ EL SERVICIO.",
                        e
                      )
                    }
                  ></i>
                </div>
              )}
            </div>
            <div className="fs-6 mt-2">
              <Checkbox
                {...label}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                checked={termsAccepted}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  marginLeft: 0,
                  paddingLeft: 0,
                  marginRight: 0,
                  paddingRight: 0,

                  "&.Mui-checked": {
                    color: green[600],
                  },
                }}
              />{" "}
              ACEPTO TÉRMINOS Y CONDICIONES.{" "}
              <span className="text-primary" onClick={() => setModalShow(true)}>
                VER
              </span>
            </div>
            <div className="text-center">
              {registeringLoader ? (
                <button
                  id="login-btn"
                  className="py-2 mt-3 btn btn-dark shadow-lg fs-5 w-100 confirmButton d-flex justify-content-center align-items-center"
                  type="button"
                >
                  <Spinner animation="grow" variant="light" className="me-3" />
                  REGISTRANDO...
                </button>
              ) : (
                <>
                  <button
                    disabled={!termsAccepted}
                    id="login-btn"
                    type="button"
                    onClick={() => {
                      const requiredValues = [
                        phone,
                        email,
                        name,
                        password,
                        reachedBy,
                      ];
                      if (reachedBy === "RECOMENDADO" && !recommendedBy)
                        return toast.error(
                          "SI LLEGÓ A NOSOTROS RECOMENDADO, DEBE INGRESAR IDENTIFICADOR DE LA PERSONA QUE LE RECOMENDÓ EL SERVICIO."
                        );
                      if (requiredValues.includes(""))
                        return toast.error("DEBE COMPLETAR TODOS LOS CAMPOS.");
                      setModalConfirmShow(true);
                    }}
                    className="py-2 px3 mt-3 btn btn-dark shadow-lg fs-5 w-100 confirmButton"
                  >
                    CONFIRMAR REGISTRO
                  </button>
                  <RegisterConfirmationModal
                    modalConfirmShow={modalConfirmShow}
                    onHide={() => {
                      setModalConfirmShow(false);
                    }}
                    phone={phone}
                    name={name}
                    email={email}
                    password={password}
                    reachedBy={reachedBy}
                    handleSubmit={handleSubmit}
                  />
                </>
              )}
            </div>
          </form>
          <hr className="mt-4" />
          <div
            className="text-center text-primary mb-4"
            onClick={resetRegister}
          >
            CANCELAR
          </div>
        </>
      ) : (
        <>
          <form action="" onSubmit={handleValidate}>
            <div className="mb-4">
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <TextField
                  autoComplete="off"
                  id="code"
                  label={
                    <span>
                      <i className="bi bi-key fs-4 me-2"></i>CÓDIGO DE
                      ACTIVACIÓN
                    </span>
                  }
                  variant="standard"
                  inputProps={{
                    style: { fontSize: "1.3rem", fontWeight: "500" },
                  }}
                  value={activation.code}
                  onChange={(e) =>
                    e.target.value.trim().length <= 7 &&
                    setActivation({
                      ...activation,
                      code: e.target.value.toUpperCase().trim(),
                    })
                  }
                  className={styles.customerLoginInput}
                />
              </Box>
            </div>
            {validatingLoader ? (
              <button
                id="login-btn"
                type="button"
                className="py-2 px3 mt-3 btn btn-dark shadow-lg fs-5 w-100 d-flex justify-content-center confirmButton"
              >
                <Spinner animation="grow" variant="light" className="me-3" />
                VALIDANDO...
              </button>
            ) : (
              <button
                id="login-btn"
                type="submit"
                className="py-2 px3 mt-3 btn btn-dark shadow-lg fs-5 w-100 d-flex justify-content-center confirmButton"
              >
                VALIDAR CÓDIGO
              </button>
            )}
          </form>
          <hr className="mt-4" />
          <span className="d-flex justify-content-center">
            <Link to="/productos" className="text-primary fs-6">
              AUN NO TENGO CÓDIGO, OBTENER.
            </Link>
          </span>
        </>
      )}

      <CustomTooltip
        showTip={showTip}
        tollTipText={tollTipText}
        target={target}
      />
      <TermsInRegisterModal
        modalShow={modalShow}
        handleConfirm={() => {
          setModalShow(false);
          setTermsAccepted(true);
        }}
        handleDimiss={() => {
          setModalShow(false);
        }}
      />
    </div>
  );
}

export default Register;

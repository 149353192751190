import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./Customer.module.css";

import { toast } from "react-toastify";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ResendActivationModal from "./ResendActivationModal";
import Spinner from "react-bootstrap/Spinner";

function PasswordReset() {
  const [phone, setPhone] = useState("");
  const [activationCode, setActivationCode] = useState("");
  const [validatingLoader, setValidatingLoader] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  const navigate = useNavigate();

  const handleSend = async () => {
    setModalShow(false);
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/activaciones/reset`,
      data: { phone },
    });
    if (response.data.msg) return toast.error(response.data.msg);
    return toast.success("SE HA ENVIADO EL CÓDIGO DE ACTIVACIÓN A SU CELULAR.");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setValidatingLoader(true);
    if (!phone || !activationCode) {
      setValidatingLoader(false);
      return toast.error("DEBE INGRESAR TELÉFONO Y CÓDIGO PARA RESTABLECER.");
    }
    const response = await axios({
      url: process.env.REACT_APP_API_URL + "/api/clientes/reset",
      method: "post",
      data: { phone, activationCode },
    });
    console.log(response.data);
    if (response.data.msg === "ok") {
      toast.success("RECIBIRÁ UNA NUEVA CONTRASEÑA EN SU CELULAR.");
      return navigate("/clientes/login");
    }
    setValidatingLoader(false);
    return toast.error(response.data.msg);
  };

  return (
    <div>
      <h1 className="text-center mb-4 welcomeTitle">RESTABLECER CONTRASEÑA</h1>
      <p className="text-center fs-6 text-dark fw-semibold mb-4 px-md-5 px-3">
        INGRESE TELÉFONO Y CÓDIGO DE ACTIVACIÓN RECIBIDO AL REGISTRAR EL
        SERVICIO.
      </p>
      <div className={styles.formContainer}>
        <form action="" onSubmit={handleSubmit}>
          <div className="mb-4">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                id="phone"
                label={
                  <span>
                    <i className="bi bi-phone-fill me-2 fs-4"></i>TELÉFONO
                  </span>
                }
                variant="standard"
                type="number"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  style: { fontSize: "1.3rem", fontWeight: "500" },
                }}
                value={phone}
                onChange={(e) => setPhone(e.target.value.trim())}
                className={styles.customerLoginInput}
              />
            </Box>
          </div>
          <div className="mb-4">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                id="activationCode"
                label={
                  <span>
                    <i className="bi bi-key fs-4 me-2"></i>CÓDIGO DE ACTIVACIÓN
                  </span>
                }
                variant="standard"
                inputProps={{
                  style: { fontSize: "1.3rem", fontWeight: "500" },
                }}
                value={activationCode}
                onChange={(e) =>
                  setActivationCode(e.target.value.toUpperCase().trim())
                }
                className={styles.customerLoginInput}
              />
            </Box>
            <p
              className="text-primary fw-semibold text-center mt-3"
              style={{ fontSize: "0.8rem" }}
              onClick={() => {
                phone
                  ? setModalShow(true)
                  : toast.error(
                      "DEBE INGRESAR TELÉFONO PARA PODER ENVIAR CÓDIGO DE ACTIVACIÓN."
                    );
              }}
            >
              NO RECUERDO CÓDIGO DE ACTIVACIÓN
            </p>
          </div>
          <div className="text-center">
            {validatingLoader ? (
              <button
                id="login-btn"
                type="button"
                className="py-2 px3 mt-3 btn btn-dark shadow-lg fs-5 w-100 d-flex justify-content-center"
              >
                <Spinner animation="grow" variant="light" className="me-3" />
                RESTABLECIENDO...
              </button>
            ) : (
              <button
                type="submit"
                id="login-btn"
                className="py-2 px3 mt-3 btn btn-dark shadow-lg fs-5 w-100 d-flex justify-content-center"
              >
                RESTABLECER
              </button>
            )}
          </div>
        </form>
        <hr className="mt-4" />
        <span className="d-flex justify-content-center">
          <Link to="/clientes/login" className="text-primary fs-6">
            VOLVER A INICIO DE SESIÓN
          </Link>
        </span>
      </div>
      <ResendActivationModal
        phone={phone}
        modalShow={modalShow}
        handleSend={handleSend}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}

export default PasswordReset;

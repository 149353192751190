import Modal from "react-bootstrap/Modal";
import placaPropiedad from "../../img/placaPropiedad.png";
import placaPuerta from "../../img/placaPuerta.png";
import stickerAuto from "../../img/stickerAuto.png";

const images = {
  auto: stickerAuto,
  puerta: placaPuerta,
  exterior: placaPropiedad,
};

function FaqExampleModal({ modalShow, modalData, onHide }) {
  return (
    modalData && (
      <Modal
        show={modalShow}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="fs-4">EJEMPLO {modalData.title}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={images[modalData.image]}
            alt={"Ejemplo de " + modalData.title}
            className="img-fluid"
          />
          <h3 className="fs-6">CARACTERÍSTICAS:</h3>
          {modalData.text}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={onHide} className="btn btn-dark">
            CERRAR
          </button>
        </Modal.Footer>
      </Modal>
    )
  );
}

export default FaqExampleModal;

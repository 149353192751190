import Modal from "react-bootstrap/Modal";

function ConfirmModal({ modalShow, notifyTo, handleSend, onHide }) {
  return (
    notifyTo.text && (
      <Modal
        show={modalShow}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            CONFIRMACIÓN
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            SE ENVIARÁ EL MENSAJE{" "}
            <strong>"{notifyTo.text.toUpperCase()}"</strong> AL PROPIETARIO DE{" "}
            <strong>{notifyTo.receiver.toUpperCase()}</strong>.
          </p>
          <p>¿DESEA CONTINUAR?</p>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={onHide} className="btn btn-dark">
            CANCELAR
          </button>
          <button
            type="button"
            onClick={() => handleSend(notifyTo.msgId)}
            className="btn btn-success"
          >
            CONTINUAR
          </button>
        </Modal.Footer>
      </Modal>
    )
  );
}

export default ConfirmModal;

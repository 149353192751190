import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

function CustomAccordion({ number, question, text }) {
  const [expanded, setExpanded] = useState(false);
  return (
    text && (
      <div className={expanded ? "my-3" : ""}>
        <Accordion
          className={expanded ? "faqAccordion selected" : "faqAccordion"}
        >
          <AccordionSummary
            onClick={() => setExpanded((prev) => !prev)}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={"panel1a-content" + number}
            id={"panel1a-header" + number}
          >
            <Typography>{question}</Typography>
          </AccordionSummary>
          <AccordionDetails>{text}</AccordionDetails>
        </Accordion>
      </div>
    )
  );
}

export default CustomAccordion;

import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

function ThanksPage() {
  const searchParams = useSearchParams()[0];

  const setCopyItem = useState({
    value: "",
    copied: false,
  })[1];

  return (
    <div className="thanksPage">
      <h1 className="text-center">¡BIENVENIDO/A!</h1>
      <hr />
      <p>SE HA PROCESADO EL PAGO CON ÉXITO.</p>
      <div className="text-start">
        <p>
          PARA REGISTRARTE COMO USUARIO Y EMPEZAR A UTILIZAR EL SERVICIO,
          PRESIONA{" "}
          <Link
            to={`/clientes/registro?codigo=${searchParams.get("codigo")}`}
            className="text-primary"
          >
            AQUÍ
          </Link>
          .
        </p>
      </div>
      <p className="">TU CÓDIGO DE ACTIVACIÓN ES:</p>
      <p>
        <span className="fw-semibold me-2 py-1 px-2 code">
          {searchParams.get("codigo")}
        </span>
        <CopyToClipboard
          text={searchParams.get("codigo")}
          onCopy={() => {
            setCopyItem({ copied: true });
            toast.info("CÓDIGO COPIADO.");
          }}
        >
          <i className="bi bi-clipboard2-check copyButton"></i>
        </CopyToClipboard>
      </p>
      <p>
        SUGERIMOS QUE LO GUARDES, PUEDE QUE LO NECESITES SI DESEAS REALIZAR
        ALGUNA GESTIÓN SOBRE TU SERVICIO.
      </p>
      <hr />
    </div>
  );
}

export default ThanksPage;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import avisaleLogo from "../../img/avisaleLogo.png";
import styles from "./Login.module.css";
import { useDispatch } from "react-redux";
import { login } from "../../redux/userSlice";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await axios({
      url: process.env.REACT_APP_API_URL + "/usuarios/tokens",
      method: "post",
      data: { username, password },
    });
    const { token, role, user } = response.data;
    if (response.data.token && role === "seller") {
      dispatch(
        login({
          token,
          role,
          user,
        })
      );
      navigate("/ventas");
    } else {
      setError("Credenciales incorrectas.");
    }
  };

  return (
    <div className={`row g-0 ${styles.homeRow}`}>
      <div
        className={`col-md-6 d-flex justify-content-center align-items-center ${styles.homeLeft}`}
      >
        <img
          src={avisaleLogo}
          id="brandLogo"
          className="w-100 px-4"
          alt="Logo de avisale.com.uy"
        />
      </div>
      <div
        className={`col-md-6 ${styles.homeRigth} d-flex justify-content-center flex-column`}
        id="homeRight"
      >
        <h1 className="text-center fs-1 mb-4">PANEL VENTAS</h1>
        <form action="" onSubmit={handleSubmit}>
          <div className="mb-2">
            <label htmlFor="username"></label>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="INGRESE USUARIO..."
              className="p-3 form-control shadow-lg"
              value={username}
              onFocus={() => setError("")}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password"></label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="INGRESE CLAVE..."
              className="p-3 form-control shadow-lg"
              onFocus={() => setError("")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error && <p className="text-danger text-center fs-5">{error}</p>}
          <div className="text-center">
            <button
              id="login-btn"
              className="py-2 px3 mt-3 btn btn-dark shadow-lg"
            >
              INICIAR SESIÓN
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;

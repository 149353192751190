import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import CustomTooltip from "../CustomTooltip";
import Offcanvas from "react-bootstrap/Offcanvas";
import DashboardModal from "./DashboardModal";

function Dashboard() {
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [showTip, setShowTip] = useState(false);
  const [tollTipText, setTollTipText] = useState("");
  const [helpTipPlacement, setHelpTipPlacement] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState("");

  const target = useRef(null);

  const handleModal = (type) => {
    setModalType(type);
    setModalShow(true);
  };

  const handleTip = (text, selected, placement) => {
    target.current = selected.target;
    setTollTipText(text);
    setHelpTipPlacement(placement);
    setShowTip(!showTip);
  };

  const user = useSelector((state) => state.user);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (showTip && target.current !== e.target) setShowTip(false);
    });
    return () => {
      document.removeEventListener("click", (e) => {
        if (showTip && target.current !== e.target) setShowTip(false);
      });
    };
  });

  useEffect(() => {
    try {
      const getCustomer = async () => {
        const response = await axios({
          url: `${process.env.REACT_APP_API_URL}/api/clientes/profile`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setCustomer(response.data);
      };
      getCustomer();
    } catch (error) {
      console.log(error); // 401
    }
    // eslint-disable-next-line
  }, []);

  return customer ? (
    <div className="d-flex flex-column customerDashboard w-100">
      <h1 className="text-center">PANEL DE {customer.name.toUpperCase()}</h1>
      <hr />
      <Link
        className="btn btn-outline-dark rounded-pill myButtonList"
        to="/clientes/vehiculos"
      >
        <i className="bi bi-car-front"></i>VEHÍCULOS
      </Link>
      <Link
        className="btn btn-outline-dark rounded-pill myButtonList"
        to="/clientes/propiedades"
      >
        <i className="bi bi-buildings"></i>PROPIEDADES
      </Link>
      <Link
        className="btn btn-outline-dark rounded-pill myButtonList"
        to="/clientes/historial"
      >
        <i className="bi bi-clock-history"></i>HISTORIAL
      </Link>
      <Link
        className="btn btn-outline-dark rounded-pill myButtonList"
        to="/clientes/perfil"
      >
        <i className="bi bi-person-lines-fill"></i>MIS DATOS
      </Link>
      <hr />
      <div className="serviceData">
        <div className="row">
          <div className="col-9">
            <h5 className="fs-5">DATOS DEL SERVICIO:</h5>
            <ul>
              <li className="d-flex align-items-center">
                {" "}
                ESTADO: {customer.isActive ? "ACTIVO" : "INACTIVO"}{" "}
                <i
                  className={
                    customer.isActive
                      ? "bi bi-circle-fill text-success ms-2"
                      : "bi bi-circle-fill text-danger ms-2"
                  }
                ></i>
                <i
                  className="bi bi-question-circle-fill ms-auto helpTip"
                  ref={target}
                  onClick={(e) =>
                    handleTip(
                      "INDICA EL ESTADO DE SU SERVICIO. EN CASO FIGURAR INACTIVO COMUNIQUESE PARA VERIFICAR.",
                      e,
                      "top"
                    )
                  }
                ></i>
              </li>
              <li className="d-flex align-items-center">
                VEHÍCULOS: {customer.vehicles.length}/{customer.totalVehicles}
                <i
                  className="bi bi-question-circle-fill ms-auto helpTip"
                  ref={target}
                  onClick={(e) =>
                    handleTip(
                      "INDICA CUANTOS VEHÍCULOS TIENE REGISTRADOS DE LA CANTIDAD DISPONIBLE EN SU SERVICIO.",
                      e,
                      "top"
                    )
                  }
                ></i>
              </li>
              <li className="d-flex align-items-center">
                PROPIEDADES: {customer.estates.length}/{customer.totalEstates}
                <i
                  className="bi bi-question-circle-fill ms-auto helpTip"
                  ref={target}
                  onClick={(e) =>
                    handleTip(
                      "INDICA CUANTAS PROPIEDADES TIENE REGISTRADAS DE LA CANTIDAD DISPONIBLE EN SU SERVICIO.",
                      e,
                      "top"
                    )
                  }
                ></i>
              </li>
              <li className="d-flex align-items-center">
                REFERIDOS: {customer.recommendingTo.length}
                <i
                  className="bi bi-question-circle-fill ms-auto helpTip"
                  ref={target}
                  onClick={(e) =>
                    handleTip(
                      "INDICA EL NÚMERO DE REFERIDOS QUE CONTRATARON EL SERVICIO. RECUERDE QUE CADA 5 PERSONAS QUE RECOMIENDE, A USTED LE CORRESPONDERÁ UN AÑO DE SUSCRIPCIÓN ADICIONAL SIN COSTO.",
                      e,
                      "top"
                    )
                  }
                ></i>
              </li>
            </ul>
          </div>
          <div className="col-3 text-end">
            <i
              className="bi bi-three-dots fs-1 text-dark me-3"
              onClick={() => setShowAccountMenu(true)}
            ></i>
          </div>
        </div>
      </div>
      <Offcanvas
        show={showAccountMenu}
        placement="bottom"
        onHide={() => setShowAccountMenu(false)}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>GESTIÓN DE MI CUENTA</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul style={{ listStyle: "none", paddingLeft: 0 }}>
            <li className="mb-2" onClick={() => handleModal("globalLogout")}>
              <i className="bi bi-door-open-fill fs-5 me-1"></i>
              CERRAR SESIÓN EN TODOS LOS DISPOSITIVOS.
            </li>
            <hr />
            <li onClick={() => handleModal("unsubscribe")}>
              <i className="bi bi-x-octagon-fill fs-5 me-1"></i>BORRAR
              SUSCRIPCIÓN AL SERVICIO.
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
      <CustomTooltip
        showTip={showTip}
        tollTipText={tollTipText}
        target={target}
        placement={helpTipPlacement}
      />{" "}
      {modalType && (
        <DashboardModal
          type={modalType}
          modalShow={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
    </div>
  ) : (
    <Loader />
  );
}

export default Dashboard;

import axios from "axios";
import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import NewCodeModal from "./NewCodeModal";

function Activations() {
  const [activations, setVehicles] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    const getActivations = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/activaciones`
      );
      setVehicles(response.data);
    };
    getActivations();
  }, []);

  return activations.length ? (
    <div>
      <div className="text-center">
        <h1 className="text-center mt-3">Activaciones</h1>
        <button
          className=" btn btn-success border-0 rounded-0"
          onClick={() => setModalShow(true)}
        >
          Generar Código
        </button>
      </div>
      <hr />
      <Table responsive striped bordered hover className="p-0 m-0">
        <thead className="bg-dark text-light">
          <tr className="text-center">
            <th className="idTableField">Id</th>
            <th>Código</th>
            <th>Tipo</th>
            <th>Usado?</th>
            <th>Registrado</th>
            <th>Modificado</th>
            <th>Usuario</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {activations.map((activation) => (
            <tr key={activation.id}>
              <td className="idTableField">{activation.id}</td>
              <td>{activation.code}</td>
              <td>{activation.type}</td>
              <td>{activation.wasUsed ? "Si" : "No"}</td>
              <td>
                {new Date(activation.createdAt).toLocaleDateString("es-ES")}
              </td>
              <td>
                {new Date(activation.updatedAt).toLocaleDateString("es-ES")}
              </td>
              <td>{activation.userId}</td>
              <td>
                <div className="d-flex justify-content-evenly">
                  <i className="bi bi-trash3 text-secondary fs-5"></i>
                  <i className="bi bi-pencil-square text-danger fs-5"></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <NewCodeModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  ) : (
    <p className="text-danger">No hay vehículos para mostrar.</p>
  );
}

export default Activations;
